import * as React from 'react';
import {useCallback, useMemo} from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import {AppBar} from '@mui/material';
import {JsonAuthUserInfo} from '../../generated-api';
import {Link, useNavigate} from 'react-router-dom';
import {fetchAuthLogout} from '../../store/auth';
import {useAppDispatch} from '../../store';
import {useAppTranslation} from "../../services/i18n";
import {AccountCircleRounded, LogoutRounded, Menu as MenuIcon} from "@mui/icons-material";
import logo from "../../assets/images/ems_logo_2024.png";
import {getLink} from "../../helpers/files";
import {ROLE_ADMIN, ROLE_SUPERVISOR} from "../../store/codebooks";

interface PageLink {
    title: string;
    link: string;
    roleIds: number[];
}

const pageLinks: PageLink[] = [
    {title: 'Události', link: '/events', roleIds: [ROLE_ADMIN]},
    {title: 'Osoby', link: '/parties', roleIds: [ROLE_ADMIN]},
    {title: 'Osoby v události', link: '/event-parties', roleIds: [ROLE_ADMIN, ROLE_SUPERVISOR]},
    {title: 'VIP hosté', link: '/guests', roleIds: [ROLE_ADMIN]},
    {title: 'Pozvánky', link: '/invites', roleIds: [ROLE_ADMIN]},
    {title: 'Výzvy', link: '/update-invites', roleIds: [ROLE_ADMIN, ROLE_SUPERVISOR]},
    {title: 'Reporty', link: '/reports', roleIds: [ROLE_ADMIN]},
    // {title: 'Uživatelé', link: '/users'},
    // {title: 'Administrace', link: '/admin'},
];

interface Props {
    user?: JsonAuthUserInfo['user'];
    configuration?: JsonAuthUserInfo['config'];

}

const AppHeader = ({user, configuration}: Props) => {
    const roleId = user?.roleId;
    const defaultEvent = configuration?.defaultEvent;

    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const t = useAppTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const settings = [
        {title: t('Profil'), action: () => navigate('/parties/' + user?.partyId), icon: <AccountCircleRounded/>},
        {title: t('Odhlásit'), action: () => dispatch(fetchAuthLogout()), icon: <LogoutRounded/>}
    ];

    const pages = useMemo(() => {
        const pages = pageLinks.filter(p => roleId && p.roleIds.indexOf(roleId) >= 0);
        if (defaultEvent && roleId === ROLE_ADMIN) {
            pages.unshift({
                title: defaultEvent.abbr || defaultEvent.title!,
                link: '/events/' + defaultEvent.eventId,
                roleIds: [ROLE_ADMIN]
            });
        }

        return pages;

    }, [roleId, defaultEvent])

    const handleOpenNavMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    }, []);
    const handleOpenUserMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    }, []);

    const handleCloseNavMenu = useCallback(() => {
        setAnchorElNav(null);
    }, []);

    const handleSelectMenu = useCallback((page: PageLink) => {
        handleCloseNavMenu();
        navigate(page.link);
    }, [handleCloseNavMenu, navigate]);

    const handleCloseUserMenu = useCallback(() => {
        setAnchorElUser(null);
    }, []);

    return <AppBar position="static">
        <Container maxWidth="xl">
            <Toolbar disableGutters>
                <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleOpenNavMenu}
                        color="inherit"
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={!!anchorElNav}
                        onClose={handleCloseNavMenu}
                        sx={{
                            display: {xs: 'block', md: 'none'},
                        }}
                    >
                        {pages.map((page) => (
                            <MenuItem key={page.title} onClick={() => {
                                handleSelectMenu(page)
                            }}>
                                <Typography textAlign="center">[{page.title}]</Typography>
                            </MenuItem>
                        ))}
                    </Menu>
                    <img alt={'Biatlon EMS'} src={logo} style={{height: '38px', alignSelf: 'center'}}/>
                </Box>

                <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                    <div style={{alignSelf: 'center', overflowX: 'hidden'}}>
                        <img alt={'Biatlon EMS'} src={logo} style={{height: '40px', marginRight: '20px'}}/>
                    </div>
                    {pages.map((page) => (
                        <Link key={page.title} to={page.link} style={{textDecoration: 'none', marginLeft: '10px'}}>
                            <Button sx={{my: 2, display: 'block'}}>
                                {page.title}
                            </Button>
                        </Link>
                    ))}
                </Box>

                <Box sx={{flexGrow: 0, display: 'flex', alignItems: 'center'}}>
                    {user && <div style={{textAlign: 'right', marginRight: '10px'}}>
                        {user.firstName}&nbsp;{user.lastName}
                    </div>}
                    <Tooltip title={t('Nastavení')}>
                        <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                            <Avatar alt={user && [user.firstName, user.lastName].join(' ')}
                                src={(user?.photoGuid ? getLink(user.photoGuid) : '/static/images/avatar/2.jpg') || '/static/images/avatar/2.jpg'}/>
                        </IconButton>
                    </Tooltip>
                    <Menu
                        sx={{mt: '45px'}}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                    >
                        {settings.map((setting) => (
                            <MenuItem sx={{
                                padding: '8px 16px',
                                minWidth: '100px',
                                '& svg': {fontSize: '150%', margin: '0 5px -4px 0'}
                            }} key={setting.title} onClick={() => setting.action()}>
                                <Typography component={'div'} textAlign="center"><small>{setting.icon}</small> {setting.title}
                                </Typography>
                            </MenuItem>
                        ))}

                    </Menu>
                </Box>
            </Toolbar>
        </Container>
    </AppBar>;
};

export default AppHeader;
