import {Alert, Button, Grid, Tooltip, Typography} from '@mui/material';
import {FormikErrors, FormikProps} from 'formik';
import {createOption, FormProps, getOption, OptionValue} from "../../model/form";
import {useAppTranslation} from "../../services/i18n";
import FormContainer from "../form/FormContainer";
import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import {articleName, PartyMassActionProps, PartyMassActionValues, partyName} from "../../model/party";
import {createCol, DataGrid, DataGridCol, DataGridFilter, DataGridMode, DataGridState, defaultDataGridFilterState} from "../DataGrid";
import {
    GetInviteListUsingGETInviteKindsEnum,
    JsonArticleInfo,
    JsonArticleTypeInfo,
    JsonEmailMessage,
    JsonEventParty,
    JsonEventPartyImportSexEnum,
    JsonEventPartyInfo,
    JsonEventPartyInfoUpdateInviteStatusEnum,
    JsonEventPartyMassActionRequest,
    JsonEventPartyMassActionRequestActionEnum,
    JsonInviteInfo,
    JsonInviteInfoStatusEnum,
    PreviewNotificationUsingPOSTNotificationTypeEnum
} from "../../generated-api";
import CodebookValue from "../CodebookValue";
import {dateToGuiAs} from "../../helpers/date";
import {ItemsState, useAppDispatch, useAppSelector} from "../../store";
import {ButtonGroupPlain} from "../form/ButtonGroupField";
import {AlertColor} from "@mui/material/Alert/Alert";
import {updateInviteStatusOptions} from "../../model/invite";
import {ModalProps, useModal} from "../../services/modal";
import {SendRounded} from "@mui/icons-material";
import {previewNotification} from "../../store/notifications";
import {getApiResult} from "../../helpers/api";
import {EmailPreview} from "../EmailPreview";
import {fetchArticleTypes} from "../../store/articleTypes";
import {fetchArticles} from "../../store/articles";
import {EventPartyMissingInfo} from "../../pages/EventPartiesPage";
import {CheckboxField} from "../form/CheckboxField";
import {fetchInvites} from "../../store/invites";
import {AuthState} from "../../store/auth";
import {selectAuthInfo} from "../../store/selectors";

interface Props extends PartyMassActionProps<PartyMassActionValues<any>, any> {

}

interface LocalFilter extends DataGridFilter {
    action?: JsonEventPartyMassActionRequestActionEnum;
    eventId?: number;
}

interface EventPartiesGridState extends DataGridState<JsonEventPartyInfo, LocalFilter> {
}

type ShowMode = 'valid' | 'invalid' | 'all';

const showModeOptions: OptionValue[] = [
    createOption('valid', 'Ukázat jen ty v pořádku', 'Zobrazit jen osoby v pořádku', undefined, 'warning'),
    createOption('invalid', 'Chyby', 'Zobrazit jen osoby s chybou', undefined, 'warning'),
    createOption('all', 'Vše', 'Zobrazit vše', undefined, 'warning'),
];

const addPartyInfo = (key: any, title: JSX.Element | string | undefined, desc: string, severity: AlertColor) => {
    return <Tooltip key={key} title={desc}><Typography color={severity}>{title}</Typography></Tooltip>
}

type EventPartyDetailsProps = {
    item: JsonEventPartyInfo,
    filter: LocalFilter,
    invites?: JsonInviteInfo[]
}

const EventPartyDetails = (props: EventPartyDetailsProps) => {
    const {item, filter, invites} = props;

    const t = useAppTranslation();

    const {action} = filter;

    const rows: JSX.Element[] = [];

    if (!item.email && action !== JsonEventPartyMassActionRequestActionEnum.Register) {
        rows.push(addPartyInfo('email', t('Chybí email'), t('Není kam zaslat pozvánku'), 'error'));
    }

    const invite = invites?.find((i) => i.partyId === item.partyId);
    const rowStatusType: RowStatusType = getRowStatus(item, invite, action);
    switch (rowStatusType) {
        case 'pendingInvite':
            rows.push(addPartyInfo('pendingInvite',
                <>{t('Aktivní výzva')} ({getOption(invite?.status || item.updateInviteStatus, updateInviteStatusOptions)?.label})</>,
                t('Osoba má již rozpracovanou výzvu'), 'error'));
            break;
        case 'noComp':
            rows.push(addPartyInfo('noComp',
                <>{t('Žádná výplata')}</>,
                t('Osoba nemá zadané náhrady any odměny'), 'error'));
            break;
        case 'missingBankAccount':
            rows.push(addPartyInfo('noComp',
                <>{t('Chybí účet')}</>,
                t('Není kam poslat výplatu'), 'error'));
            break;
        case 'valid':
        case 'missingEmail': // already notified
        default:
            break;
    }
    if (rowStatusType !== 'pendingInvite' && item.updateInviteStatus && action === JsonEventPartyMassActionRequestActionEnum.UpdateOrg) {
        if (item.updateInviteStatus === JsonEventPartyInfoUpdateInviteStatusEnum.Pending
            || item.updateInviteStatus === JsonEventPartyInfoUpdateInviteStatusEnum.Returned
            || item.updateInviteStatus === JsonEventPartyInfoUpdateInviteStatusEnum.Accepted) {
            rows.push(addPartyInfo('pendingInvite',
                <>{t('Aktivní výzva')} ({getOption(item.updateInviteStatus, updateInviteStatusOptions)?.label})</>,
                t('Osoba má již rozpracovanou výzvu'), 'info'));
        } else {
            rows.push(addPartyInfo('pendingInvite',
                <>{t('Uzavřená výzva')} ({getOption(item.updateInviteStatus, updateInviteStatusOptions)?.label})</>,
                t('Osoba má již uzavřenou výzvu'), 'info'));
        }
    }
    if (rowStatusType !== 'pendingInvite' && invite && action !== JsonEventPartyMassActionRequestActionEnum.UpdateOrg) {
        if (invite.status === JsonInviteInfoStatusEnum.Pending
            || invite.status === JsonInviteInfoStatusEnum.Returned
            || invite.status === JsonInviteInfoStatusEnum.Accepted) {
            rows.push(addPartyInfo('pendingInvite',
                <>{t('Aktivní výzva')} ({getOption(invite.status, updateInviteStatusOptions)?.label})</>,
                t('Osoba má již rozpracovanou výzvu'), 'info'));
        } else {
            rows.push(addPartyInfo('pendingInvite',
                <>{t('Uzavřená výzva')} ({getOption(invite.status, updateInviteStatusOptions)?.label})</>,
                t('Osoba má již uzavřenou výzvu'), 'info'));
        }
    }

    if (!rows.length) {
        return null;
    }

    return <div className={'event-details'}>
        {rows}
    </div>
}

const defaultState: EventPartiesGridState = {
    filter: {
        ...defaultDataGridFilterState,
        orderCol: undefined,
        action: undefined,
        eventId: undefined
    },
};

type RowStatusType = 'valid' | 'missingEmail' | 'pendingInvite' | 'missingBankAccount' | 'noComp';

const getRowStatus = (item: JsonEventPartyInfo, invite?: JsonInviteInfo, action?: JsonEventPartyMassActionRequestActionEnum): RowStatusType => {
    if (!item.email) {
        return 'missingEmail';
    }
    if (action === JsonEventPartyMassActionRequestActionEnum.CompOrg) {
        if (!(item.eventCompDetails?.length && item.eventCompDetails?.length > 0)) {
            return 'noComp';
        }
        if (!item.bankAccount) {
            return 'missingBankAccount';
        }
    }
    if (action === JsonEventPartyMassActionRequestActionEnum.UpdateOrg && item.updateInviteStatus) {
        if (item.updateInviteStatus === JsonEventPartyInfoUpdateInviteStatusEnum.Pending
            || item.updateInviteStatus === JsonEventPartyInfoUpdateInviteStatusEnum.Returned
            || item.updateInviteStatus === JsonEventPartyInfoUpdateInviteStatusEnum.Accepted) {
            return 'pendingInvite';
        }
    }
    if (!!invite) {
        if (invite.status === JsonInviteInfoStatusEnum.Pending
            || invite.status === JsonInviteInfoStatusEnum.Returned
            || invite.status === JsonInviteInfoStatusEnum.Accepted) {
            return 'pendingInvite';
        }
    }

    return 'valid';
}

const getRowClassNames = (item: JsonEventPartyInfo, invite?: JsonInviteInfo, action?: JsonEventPartyMassActionRequestActionEnum): string[] | undefined => {
    switch (getRowStatus(item, invite, action)) {
        case 'pendingInvite':
        case 'missingEmail':
        case 'noComp':
        case 'missingBankAccount':
            return ['data-grid-invalid-row'];
    }
    return undefined;
}

const filterValid = (action: JsonEventPartyMassActionRequestActionEnum | undefined, eventParties: JsonEventPartyInfo[], showMode: ShowMode, invites?: JsonInviteInfo[]) => {
    let missingEmail = 0, pendingInvite = 0, noComp = 0, missingBankAccount = 0;
    const filtered = eventParties.filter((item) => {
        let valid = false;
        switch (getRowStatus(item, invites?.find((i) => i.partyId === item.partyId), action)) {
            case 'missingEmail':
                missingEmail++;
                break;
            case 'pendingInvite':
                pendingInvite++;
                break;
            case 'noComp':
                noComp++;
                break;
            case 'missingBankAccount':
                missingBankAccount++;
                break;
            case 'valid':
            default:
                valid = true;
        }

        return showMode === 'all' || (valid && showMode === 'valid') || (!valid && showMode === 'invalid');
    });
    return {filtered, missingEmail, pendingInvite, noComp, missingBankAccount};
}

const texts: { [key in JsonEventPartyMassActionRequestActionEnum]?: [string, string, string] } = {
    [JsonEventPartyMassActionRequestActionEnum.UpdateOrg]: [
        'Vyzvat k aktualizaci údajů',
        'Výzva bude odeslána na {{count}} osob',
        'Není nikdo, koho by bylo možné vyzvat'],
    [JsonEventPartyMassActionRequestActionEnum.TravelOrg]: [
        'Vyzvat k zadání náhrad',
        'Výzva bude odeslána na {{count}} osob',
        'Není nikdo, koho by bylo možné vyzvat'],
    [JsonEventPartyMassActionRequestActionEnum.CompOrg]: [
        'Vyzvat k potvrzení výplaty',
        'Výzva bude odeslána na {{count}} osob',
        'Není nikdo, koho by bylo možné vyzvat'],
};

const PartyUpdateOrgForm = (props: Props) => {

    const {massAction, onSave} = props;
    const {eventParties} = massAction;
    const {action, eventId} = massAction.values;

    const t = useAppTranslation();
    const dispatch = useAppDispatch();
    const modal = useModal();

    const {configuration} = useAppSelector<AuthState>(selectAuthInfo);

    const [showMode, setShowMode] = useState<ShowMode>('valid');
    const [articleTypes, setArticleTypes] = useState<JsonArticleTypeInfo[] | undefined>(undefined);
    const [articles, setArticles] = useState<JsonArticleInfo[] | undefined>(undefined);
    const [invites, setInvites] = useState<JsonInviteInfo[] | undefined>(undefined);

    const handleFetchEvent = useCallback(async (eventId: number) => {
        const articleTypes = getApiResult<JsonArticleTypeInfo[]>(await dispatch(fetchArticleTypes({eventId})));
        const articles = getApiResult<JsonArticleInfo[]>(await dispatch(fetchArticles({eventId})));
        setArticleTypes(articleTypes);
        setArticles(articles);
        if (action !== JsonEventPartyMassActionRequestActionEnum.UpdateOrg) {
            const inviteKinds: Array<GetInviteListUsingGETInviteKindsEnum> = [];
            if (action === JsonEventPartyMassActionRequestActionEnum.CompOrg) {
                inviteKinds.push(GetInviteListUsingGETInviteKindsEnum.Comp);
            } else if (action === JsonEventPartyMassActionRequestActionEnum.TravelOrg) {
                inviteKinds.push(GetInviteListUsingGETInviteKindsEnum.Travel);
            }
            const partyIds = eventParties.map(ep => ep.partyId) as number[];
            setInvites(getApiResult<JsonInviteInfo[]>(await dispatch(fetchInvites({partyIds, eventId, inviteKinds, rows: 10000}))));
        }

    }, [dispatch, action, eventParties]);

    const validate = useCallback((values: JsonEventPartyMassActionRequest) => {
        let errors = {} as FormikErrors<JsonEventPartyMassActionRequest>;
        if (!values.eventId) {
            errors.eventId = t('Vyberte událost');
        }
        return errors;
    }, [t]);

    const actions = useCallback(({values, isSubmitting}: FormikProps<JsonEventPartyMassActionRequest>, props: FormProps<JsonEventPartyMassActionRequest>) => {
        const {items} = values;
        const itemsCount = items?.length || 0;
        if (!action) {
            return null;
        }
        const textValues = texts[action];
        if (!textValues) {
            return null; // should never happen
        }

        return <Grid item xs={12} sx={{margin: '-5px 0 -10px 0'}}>
            <Grid container columnSpacing={2} justifyContent="flex-end">
                <Grid item sx={{flexGrow: 1}}>
                    {itemsCount > 0
                        ? <Alert severity={'info'} className={'event-action-errors'}>{t(textValues[1], {count: itemsCount})}</Alert>
                        : <Alert severity={'warning'} className={'event-action-errors'}>{t(textValues[2])}</Alert>}
                </Grid>
                <Grid item>
                    <CheckboxField name={'skipEmail'} color={'default'} label={t('Neposílat email')}/>
                </Grid>
                {props.onCancel && <Grid item>
					<Button variant="text" onClick={props.onCancel}>{props.cancelButtonTitle || t('Storno')}</Button>
				</Grid>}
                <Grid item>
                    <Button variant="contained" type="submit" color={'success'} disabled={isSubmitting || !itemsCount}>
                        {t(textValues[0])}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    }, [action, t]);

    const {filtered, missingEmail, pendingInvite, noComp, missingBankAccount} = useMemo(() => {
        return filterValid(action, eventParties, showMode, invites);
    }, [action, eventParties, showMode, invites])

    const cols = useMemo(() => {
        const cols: DataGridCol<JsonEventPartyInfo, LocalFilter>[] = [
            createCol('Jméno', 'fullName', 150, undefined, (v, item) => <strong>{partyName(item)}</strong>),
            createCol('P', 'sex', 15, 'Pohlaví', (v) => v ? <small><CodebookValue value={v} name={'sex'} formatValue={(v) => v[0]}/></small> : null),
            createCol('R', 'birthDate', 35, 'Rok narození', (v) => v ? <small>{dateToGuiAs(v, 'Y')}</small> : null),
            {
                title: 'Ú',
                tooltip: 'Chybějící údaje',
                size: 20,
                align: "center",
                col: 'email',
                renderValue: (v, item) => {
                    return <EventPartyMissingInfo item={item}/>
                }
            },
            createCol('Email', 'email', 150, undefined, (v) => <small>{v}</small>),
            createCol('Skupina', 'groupId', 100, 'Pracovní skupina', (v) => v ? <CodebookValue value={v} name={'group'}/> : null)];
        if (articles && articleTypes) {
            articleTypes.forEach((at) => cols.push({
                    col: 'eventArticleDetails',
                    title: at?.title?.[0] || '',
                    tooltip: at?.title,
                    size: 25,
                    align: 'left',
                    renderValue: (v, item, filter) => {
                        const a = articles.find((a) => a.articleId === v?.find((a) => a.articleTypeId === at.articleTypeId)?.prefArticleId)
                        return a ? articleName(a, true, item.sex ? JsonEventPartyImportSexEnum[item.sex] : undefined) : null;
                    }
                })
            );
        }
        cols.push({
            title: 'Stav',
            size: 100,
            col: 'updateInviteStatus',
            renderValue: (v, item, filter) => <EventPartyDetails item={item} filter={filter as LocalFilter} invites={invites}/>
        });
        return cols;
    }, [articleTypes, articles, invites]);

    const getRowClassNamesWithInvite = useCallback((item: JsonEventPartyInfo) => {
        return getRowClassNames(item, invites?.find((i) => i.partyId === item.partyId), action);
    }, [invites, action]);

    const grid = useMemo(() => {
        const itemsState = {
            loading: false,
            count: 0,
            items: filtered,
            filter: {action, eventId}
        } as ItemsState<JsonEventPartyInfo, LocalFilter>;

        return <>
            {(missingEmail > 0 || pendingInvite > 0 || noComp > 0 || missingBankAccount > 0) && <Grid container style={{padding: '5px 0 10px 0', alignItems: 'center'}}><Grid item xs={8}>
				<Alert severity={'warning'} className={'event-action-errors'}>
                    {t('Některé osoby nelze vyzvat ({{skipped}}):', {skipped: missingEmail + pendingInvite + noComp + missingBankAccount})}
                    {missingEmail > 0 && <span>{t('nemají email ({{missingEmail}})', {missingEmail})}</span>}
                    {pendingInvite > 0 && <span>{t('nedokončená výzva ({{pendingInvite}})', {pendingInvite})}</span>}
                    {noComp > 0 && <span>{t('žádná výplata ({{noComp}})', {noComp})}</span>}
                    {missingBankAccount > 0 && <span>{t('nemají účet ({{missingBankAccount}})', {missingBankAccount})}</span>}
				</Alert>
			</Grid><Grid item xs={4} sx={{textAlign: 'right'}}>
				<ButtonGroupPlain name={'showMode'} options={showModeOptions} currentValue={showMode} onChange={(v) => {
                    setShowMode(v || showMode);
                }}/>
			</Grid></Grid>}
            <DataGrid
                cols={cols}
                defaultState={defaultState}
                itemsState={itemsState}
                mode={DataGridMode.CLIENT}
                tableProps={{
                    minHeight: '100px',
                    maxHeight: "clamp(100px, calc(100vh - "
                        + (300
                            + (missingEmail > 0 || pendingInvite > 0 || noComp > 0 || missingBankAccount > 0 ? 50 : 0))
                        + "px), 400px)"
                }}
                emptyListMessage={t('Není možné vyzvat žádné osoby')}
                getRowClassNames={getRowClassNamesWithInvite}
            />
        </>;
    }, [filtered, missingEmail, pendingInvite, noComp, missingBankAccount, action, cols, eventId, showMode, t, getRowClassNamesWithInvite]);

    const children = useCallback(() => {
        return <>
            <Grid item xs={12}>
                {grid}
            </Grid>
        </>;
    }, [grid]);

    const handleSave = useCallback(async (values: JsonEventPartyMassActionRequest) => {

        if (action === JsonEventPartyMassActionRequestActionEnum.UpdateOrg) {
            if (!!values.skipEmail) {
                const result = await modal.confirm({
                    title: t('Potvrzení vytvoření výzvy'),
                    message: <div>
                        <p>{t('Pro zvolené osoby ({{count}}) bude vytvořena výzva, bez odeslání emailu.', {count: values.items?.length})}</p>
                    </div>,
                    cancelText: 'Zpět',
                    confirmColor: 'success',
                    confirmText: t('Vytvořit výzvy ({{count}})', {count: values.items?.length}),
                    confirmIcon: <SendRounded/>,
                } as ModalProps);
                if (result !== 'CONFIRM') {
                    return;
                }

            } else {
                const email = getApiResult<JsonEmailMessage>(await dispatch(previewNotification({
                    request: {
                        invite: {...values.invite, eventId},
                        recipients: values.items?.map((ep) => filtered.find((f) => f.partyId === ep.partyId)?.email || ('' + ep.partyId))
                    },
                    notificationType: PreviewNotificationUsingPOSTNotificationTypeEnum.UpdateOrg
                })));

                const result = await modal.confirm({
                    title: t('Potvrzení odeslání výzvy'),
                    message: <div>
                        <p>{t('Na zvolené osoby ({{count}}) bude odeslána následující výzva', {count: values.items?.length})}:</p>
                        <EmailPreview email={email} showRecipient/>
                    </div>,
                    cancelText: 'Zpět',
                    confirmColor: 'success',
                    confirmText: t('Odeslat všem příjemcům ({{count}})', {count: values.items?.length}),
                    confirmIcon: <SendRounded/>,
                } as ModalProps);
                if (result !== 'CONFIRM') {
                    return;
                }
            }
        }

        if (onSave && massAction) {
            onSave({...massAction, values: values})
        }
    }, [filtered, eventId, action, massAction, onSave, modal, dispatch, t]);

    const initialValues = useMemo(() => {
        return {
            ...massAction.values,
            items: filtered as JsonEventParty[] | undefined,
            invite: {
                replyUntil: configuration?.defaultEvent?.eventData?.replyUntilOrgUpdate,
                inviteData: {}
            }
        };
    }, [massAction, filtered, configuration?.defaultEvent?.eventData?.replyUntilOrgUpdate]);

    useEffect(() => {
        if (eventId) {
            handleFetchEvent(eventId).then();
        }
    }, [eventId, handleFetchEvent]);

    return <FormContainer
        item={initialValues}
        validate={validate}
        actions={actions}
        children={children}
        onCancel={props.onCancel}
        onSave={handleSave}
    />;
}

export default PartyUpdateOrgForm;
