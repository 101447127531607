import React from 'react';
import {Navigate, Route, Routes} from 'react-router';
import {BrowserRouter} from 'react-router-dom';
import Layout from '../components/layout/Layout';
import {ModalProvider} from '../services/modal';
import {baseUrl} from './config';
import LoginPage from "../pages/LoginPage";
import EventsPage from "../pages/EventsPage";
import PartiesPage from "../pages/PartiesPage";
import EventPage from "../pages/EventPage";
import PartyPage from "../pages/PartyPage";
import EventPartiesPage from "../pages/EventPartiesPage";
import RsvpPage from "../pages/RsvpPage";
import InvitesPage from "../pages/InvitesPage";
import {JsonAuthRequestTokenTypeEnum} from "../generated-api";
import UpdateInvitesPage from "../pages/UpdateInvitesPage";
import EventGuestsPage from "../pages/EventGuestsPage";
import {ROLE_ADMIN} from "../store/codebooks";
import EventReportsPage from "../pages/EventReportsPage";
import {useAppSelector} from "../store";
import {AuthState} from "../store/auth";
import {selectAuthInfo} from "../store/selectors";
import PlanPage from "../pages/PlanPage";
import AmmoPage from "../pages/AmmoPage";

// https://reactrouter.com/docs/en/v6/getting-started/overview
export const AppRouter: React.FC = () => {

    const {user} = useAppSelector<AuthState>(selectAuthInfo);
    const isLogged = !!user?.userId;
    const isAdmin = isLogged && user.roleId === ROLE_ADMIN;

    return (
        <BrowserRouter basename={baseUrl}>
            <Layout>
                <ModalProvider>
                    <Routes>
                        {isAdmin && <Route path='/events' element={<EventsPage/>}/>}
                        {/*{isLogged && <Route path='/events/new' element={<EditCompanyPage createNew={true}/>}/>}*/}
                        {isAdmin && <Route path='/events/:eventId' element={<EventPage/>}/>}
                        {isAdmin && <Route path='/events/:eventId/:tab' element={<EventPage/>}/>}

                        {/*{ isLogged && <Route path='/users' element={<ListUsersPage />} /> }*/}
                        {/*{ isLogged && <Route path='/users/new' element={<EditUserPage createNew={true}/>} /> }*/}
                        {/*{ isLogged && <Route path='/users/:id' element={<EditUserPage />} /> }*/}

                        {isAdmin && <Route path='/parties' element={<PartiesPage/>}/>}
                        {isLogged && <Route path='/parties/:partyId' element={<PartyPage/>}/>}
                        {isLogged && <Route path='/parties/:partyId/:eventId' element={<PartyPage/>}/>}

                        {isLogged && <Route path='/event-parties' element={<EventPartiesPage/>}/>}
                        {isAdmin && <Route path='/guests' element={<EventGuestsPage/>}/>}
                        {isAdmin && <Route path='/guests/:tab' element={<EventGuestsPage/>}/>}
                        {isAdmin && <Route path='/guests/:tab/:eventId' element={<EventGuestsPage/>}/>}

                        {isLogged && <Route path='/invites' element={<InvitesPage/>}/>}
                        {isLogged && <Route path='/update-invites' element={<UpdateInvitesPage/>}/>}

                        {isAdmin && <Route path='/reports' element={<EventReportsPage/>}/>}
                        {isAdmin && <Route path='/reports/:report' element={<EventReportsPage/>}/>}

                        {isLogged && (isAdmin
                            ? <Route path="*" element={<Navigate to="/parties" replace/>}/>
                            : <Route path="*" element={<Navigate to="/event-parties" replace/>}/>)}

                        {!isLogged && <Route path="/recover">
							<Route index element={<LoginPage tokenType={JsonAuthRequestTokenTypeEnum.Recover}/>}/>
							<Route path=":token" element={<LoginPage tokenType={JsonAuthRequestTokenTypeEnum.Recover}/>}/>
						</Route>}
                        {!isLogged && <Route path="/activate">
							<Route index element={<LoginPage tokenType={JsonAuthRequestTokenTypeEnum.Activate}/>}/>
							<Route path=":token" element={<LoginPage tokenType={JsonAuthRequestTokenTypeEnum.Activate}/>}/>
						</Route>}

                        <Route path='*' element={<LoginPage/>}/>
                    </Routes>
                </ModalProvider>
            </Layout>
        </BrowserRouter>
    );
};

export const PublicAppRouter: React.FC = () => {
    return (
        <BrowserRouter>
            <Layout noHeader>
                <Routes>
                    {/*<Route path='/registration/:code' element={<RegistrationPage />} />*/}
                </Routes>
            </Layout>
        </BrowserRouter>
    );
};

export const RsvpRouter: React.FC = () => {
    return (
        <BrowserRouter>
            <div className={'container'}>
                <Routes>
                    <Route path='/rsvp/:token' element={<RsvpPage/>}></Route>
                    <Route path='/info/:token' element={<RsvpPage/>}></Route>
                    <Route path='/plan/:dayDate/:eventFloorDayId' element={<PlanPage/>}></Route>
                    <Route path='/ammo' element={<AmmoPage/>}></Route>
                </Routes>
            </div>
        </BrowserRouter>
    );
};
