import {Button, Chip, Container, Grid, Link, Tooltip} from '@mui/material';
import * as React from 'react';
import {useCallback, useMemo, useState} from 'react';
import {useAppTranslation} from '../services/i18n';
import {fetchParties, fetchPartiesCount, fetchPartiesOnline, fetchParty, fetchSiwiPartyList, mergeParties, pushPartyToSiwi, saveParty} from '../store/parties';
import {Link as RouterLink, useNavigate} from "react-router-dom";
import PageHeader from '../components/layout/PageHeader';
import {
    GetPartyCountUsingGETRequest,
    GetPartyListUsingGETRequest,
    JsonEventPartyMassActionRequestActionEnum,
    JsonFindPartyResponseMatchTypesEnum,
    JsonParty,
    JsonPartyEventDetails,
    JsonPartyEventDetailsStatusEnum,
    JsonPartyInfo,
    JsonPartyInfoPartyTypeEnum,
    JsonPartyInfoSexEnum,
    JsonPartyInfoSiwiSyncStatusEnum,
    JsonPartyPartyTypeEnum,
    JsonPartySexEnum,
    JsonPartySiwiSyncStatusEnum,
    JsonSiwiPartyInfo
} from '../generated-api';
import {createCol, DataGrid, DataGridCol, DataGridFilter, DataGridFilterProps, DataGridItemAction, DataGridMasAction, DataGridState, defaultDataGridFilterState} from "../components/DataGrid";
import {useAppDispatch, useAppSelector} from "../store";
import {selectAuthInfo, selectCodebooks, selectParties} from "../store/selectors";
import {TextFormField} from "../components/form/TextFormField";
import {ButtonGroupField} from "../components/form/ButtonGroupField";
import {SelectFormField} from "../components/form/SelectFormField";
import CodebookValue from "../components/CodebookValue";
import {datetimeToGui, dateToGuiAs} from "../helpers/date";
import {
    EventPartyImportResult,
    isInvitedOptions,
    isPhotoOptions,
    isSiwiOptions,
    isUserOptions,
    PartyMassActionValues,
    partyName,
    partyTypeOptions,
    sexOptions,
    siwiSyncStatusesOptions
} from "../model/party";
import {addApiResultMessage, ApiChangeType, getApiResult} from "../helpers/api";
import PartyMassActionModal, {createMassAction} from "./PartyMassActionModal";
import {createOption, FAKE_VALUE_EMPTY, getCodebookLabel, getOption} from "../model/form";
import {PartyPhotoTooltip} from "../components/party/PartyPhoto";
import {
    AddCircleOutlineOutlined,
    CheckBoxOutlineBlankRounded,
    CheckBoxRounded,
    CloudUploadOutlined,
    EditRounded,
    EventAvailableRounded,
    GroupAddOutlined,
    InterpreterModeRounded,
    ManageAccountsRounded,
    PersonRounded,
    ScheduleRounded,
    WorkspacePremiumRounded
} from "@mui/icons-material";
import PartyTags from "../components/PartyTags";
import PartyImportModal from "./PartyImportModal";
import {shortId, shortSiwi} from "../helpers/format";
import PartyModal from "./PartyModal";
import {FIXED_INVITE_TAG_ID} from "../store/codebooks";
import FormModal from "../components/form/FormModal";
import PartyInfoBox from "../components/party/PartyInfoBox";
import {FoundParty} from "../components/party/FoundParty";
import {AuthState} from "../store/auth";
import {addMessage} from "../store/localApp";

interface PartiesGridFilter extends DataGridFilter, GetPartyListUsingGETRequest {
}

interface PartiesGridState extends DataGridState<JsonPartyInfo, PartiesGridFilter> {
}

export const EventDetails = ({col, eventDetails, filter}: { col: keyof JsonPartyEventDetails, eventDetails: JsonPartyEventDetails[] | undefined, filter?: PartiesGridFilter }) => {
    const filterEventIds = filter?.eventIds;

    const details: { value: any, eventIds: number[] }[] = [];
    if (eventDetails && eventDetails.length > 0) {
        eventDetails.forEach((ed) => {
            if (ed.status === JsonPartyEventDetailsStatusEnum.Deleted
                || !ed[col]
                || !ed.eventId
                || (filterEventIds && filterEventIds.length > 0 && !filterEventIds.find((eventId) => eventId === ed.eventId))) {
                return;
            }
            const d = details.find((d) => d.value === ed[col]);
            if (d) {
                d.eventIds.push(ed.eventId);
            } else {
                let value;
                if (col === 'groupId') {
                    value = <CodebookValue value={ed[col]} name={'group'}/>
                } else {
                    value = ed[col];
                }
                details.push({value, eventIds: [ed.eventId]});
            }
        });
    }
    if (!details) {
        return null;
    }

    return <div className={'event-details'}>
        {details.map((detail, i) => {
            return <Tooltip key={i} enterDelay={1000} enterNextDelay={1000} title={detail.eventIds.map(
                (eventId) => <div key={eventId}><CodebookValue value={eventId} name={'event'}/></div>)
            }><span>{detail.value}</span></Tooltip>
        })}
    </div>
}

type SiwiStatusDetailProps = {
    item: JsonPartyInfo,
    short?: boolean
}

export const SiwiStatusDetail = (props: SiwiStatusDetailProps) => {
    const {item, short} = props;
    const {partyId, siwiId, siwiSyncStatus} = item;

    const t = useAppTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const {configuration} = useAppSelector<AuthState>(selectAuthInfo);
    const defaultEventId = configuration?.defaultEvent?.eventId as number;

    const [siwiParties, setSiwiParties] = useState<JsonSiwiPartyInfo[] | undefined>(undefined);
    const [mySiwiId, setMySiwiId] = useState<string | undefined>(siwiId);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [targetParty, setTargetParty] = useState<JsonPartyInfo | undefined>({partyId});

    const handleFetchSiwiParties = useCallback(async () => {
        if (!partyId) {
            return;
        }
        setSiwiParties(getApiResult(await dispatch(fetchSiwiPartyList(partyId))));
    }, [partyId, dispatch]);

    const handleCloseSiwiParties = useCallback(() => {
        setSiwiParties(undefined);
    }, []);

    const handleSaveNew = useCallback(async () => {
        if (!partyId) {
            return;
        }
        const ignoreMatches = siwiParties?.map((sp) => partyId + '_' + sp.siwiId) || [];
        const item = getApiResult<JsonPartyInfo>(await dispatch(pushPartyToSiwi({partyId, ignoreMatches})));
        if (item?.siwiId) {
            setMySiwiId(item.siwiId);
            setSiwiParties(undefined);
        } else {
            await handleFetchSiwiParties();
        }

    }, [partyId, dispatch, siwiParties, handleFetchSiwiParties]);

    const handleSaveLink = useCallback(async (siwiParty: JsonSiwiPartyInfo, targetParty: JsonPartyInfo) => {
        if (!partyId) {
            return;
        }
        let item = getApiResult<JsonParty>(await dispatch(fetchParty(partyId)));
        if (!item || !!item.siwiId) {
            return;
        }
        let mergedParty;
        if (!!siwiParty.existingParty?.partyId && siwiParty.partyId) {
            // will fail if the deleted party exists in the current event
            const res = await dispatch(mergeParties({
                deletePartyId: siwiParty.partyId,
                targetPartyId: siwiParty.existingParty.partyId,
            }));
            mergedParty = getApiResult<JsonParty>(res);
            if (mergedParty) {
                dispatch(addMessage({
                    code: 'OK',
                    action: 'party/merge',
                    severity: 'success',
                    title: t('Osoba úspěšně smazána jako duplikát, informace přesunuty do původní existující osoby {{title}}, která je nyní otevřena', {
                        title: partyName(mergedParty) + ' (' + mergedParty.siwiId + ')'
                    }),
                }));
                item = mergedParty;
                setMySiwiId(siwiParty.siwiId);
            }
        }

        // will fail for existing SIWI IDs
        const res = await dispatch(saveParty({
            ...item,
            siwiId: siwiParty.siwiId,
            siwiSyncStatus: JsonPartySiwiSyncStatusEnum.Dirty,
            firstName: targetParty.firstName || item.firstName,
            lastName: targetParty.lastName || item.lastName,
            photoGuid: targetParty.photoGuid || item.photoGuid,
            sex: (targetParty.sex ? JsonPartySexEnum[targetParty.sex] : undefined) || item.sex
        }));

        if (getApiResult(res)) {
            if (!mergedParty) {
                addApiResultMessage(res, {
                    [ApiChangeType.NO_CHANGE]: ['Osoba {{title}} ponechána beze změn', partyName],
                    [ApiChangeType.UPDATED]: ['Osoba {{title}} úspěšně spojena zapsáním SIWI ID', partyName],
                }, t, dispatch);
            }
            setMySiwiId(siwiParty.siwiId);
            setSiwiParties(undefined);
        }

        if (mergedParty?.partyId) {
            navigate('/parties/' + mergedParty.partyId, {replace: true});
        }

    }, [partyId, dispatch, navigate, t]);

    return useMemo(() => {
        if (!!mySiwiId) {
            return <pre title={mySiwiId}>{short ? shortSiwi(mySiwiId) : mySiwiId}</pre>
        }
        if (siwiSyncStatus === JsonPartyInfoSiwiSyncStatusEnum.Conflict) {
            const o = getOption(siwiSyncStatus, siwiSyncStatusesOptions);

            const isSelected = targetParty?.partyId === FAKE_VALUE_EMPTY;
            return <>
                <div className={'party-tags'}>
                    <Chip key={'quick'} size={'small'} className={'party-tags-add'}
                        label={o.icon} title={t('Otevřít formulář pro řešení konfliktu', {title: o.label})}
                        onClick={handleFetchSiwiParties}
                    />
                </div>
                {siwiParties !== undefined && <FormModal title={t('Vyřešení konfliktu')} onCancel={handleCloseSiwiParties} dialogClassName={'rsvp-review'} maxWidth={'sm'}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<PartyInfoBox party={item} simple eventId={defaultEventId}/>
						</Grid>
						<Grid item xs={12}>
							<Button title={undefined} variant={'contained'} size={'small'} fullWidth style={{marginBottom: '-5px'}}
								color={isSelected ? 'info' : 'inherit'}
								onClick={() => {
                                    setTargetParty((tp) => ({...tp, partyId: isSelected ? undefined : FAKE_VALUE_EMPTY}));
                                }}>
                                {isSelected ? <CheckBoxRounded/> : <CheckBoxOutlineBlankRounded/>}
								<span>{t('Založit jako novou osobu')}</span>
							</Button>
						</Grid>
						<Grid item xs={12}>
							<Grid container spacing={2}>
                                {siwiParties.map((sp) => {
                                    const p: JsonPartyInfo = sp.existingParty ?
                                        {
                                            ...sp.existingParty,
                                            partyId: sp.siwiPartyId // overloaded
                                        }
                                        : {
                                            ...sp,
                                            partyType: JsonPartyInfoPartyTypeEnum.Fo,
                                            partyId: sp.siwiPartyId, // overloaded
                                            allowedActions: undefined,
                                            sex: sp.sex === 'M' || sp.sex === 'F' ? JsonPartyInfoSexEnum[sp.sex] : undefined,
                                            status: undefined
                                        };
                                    return <FoundParty key={sp.siwiPartyId} sourceParty={{...item, partyId: undefined}} foundPartyResponse={{
                                        photoScore: sp.photoScore,
                                        matchTypes: [JsonFindPartyResponseMatchTypesEnum.Photo, JsonFindPartyResponseMatchTypesEnum.Name],
                                        party: p
                                    }} targetParty={targetParty} maxWidth={80} handleValueChange={(name, value) => {
                                        const isLink = (name === 'partyId' && value !== FAKE_VALUE_EMPTY);
                                        setTargetParty((tp) => {
                                            const p = {
                                                firstName: isLink ? item.firstName : tp?.firstName,
                                                lastName: isLink ? item.lastName : tp?.lastName,
                                                photoGuid: isLink ? item.photoGuid : tp?.photoGuid,
                                                sex: isLink ? (item.sex ? JsonPartyInfoSexEnum[item.sex] : undefined) : tp?.sex,
                                                ...tp,
                                                [name]: value
                                            };
                                            return p;
                                        });
                                    }} isSiwi={!sp.existingParty} help={<div>
                                        {!!sp.existingParty
                                            ? (!!sp.existingEventParty
                                                ? t('Tato osoba již existuje v EMS a bohužel také v události. Spojení osob není jednoduše možné a je nutný servisní zásah (je třeba rozhodnout, jaké platí dny, pozvánky, akreditace atd.)')
                                                : t('Tato osoba již existuje v EMS (ale naštěstí ne v události). ' +
                                                    'Spojením bude řešená osoba smazána jako duplikát a všechny její informace budou přesunuty této existující osobě (pozvánky atd.).'))
                                            : t('Tato osoba je zatím pouze v SIWI, nic dalšího o ní EMS tedy neví. ' +
                                                'Spojením bude řešené osobě přiřazeno toto SIWI ID.')}
                                    </div>}/>;
                                })}
							</Grid>
						</Grid>
						<Grid item sx={{flexGrow: 1}}>
						</Grid>
						<Grid item>
							<Button variant="text" onClick={handleCloseSiwiParties}>{t('Storno')}</Button>
						</Grid>
						<Grid item xs={3}>
							<Button title={undefined} variant={'contained'} size={'small'} fullWidth
								disabled={targetParty?.partyId === undefined || isSubmitting}
								color={'success'}
								onClick={async () => {
                                    setIsSubmitting(true);
                                    if (targetParty?.partyId === FAKE_VALUE_EMPTY) {
                                        await handleSaveNew();
                                    } else if (targetParty?.partyId) {
                                        const sp = siwiParties?.find((sp) => sp.siwiPartyId === targetParty.partyId)
                                        if (sp) {
                                            await handleSaveLink(sp, targetParty);
                                        }
                                    }
                                    setIsSubmitting(false);
                                }}>
								<span>{t('Uložit')}</span>
							</Button>
						</Grid>
					</Grid>
				</FormModal>}
            </>
        }
        return null;

    }, [mySiwiId, targetParty, isSubmitting,
        item, siwiSyncStatus, siwiParties, short, defaultEventId,
        handleFetchSiwiParties, handleCloseSiwiParties, handleSaveNew, handleSaveLink, t]);
}

type EditPartyState = { party: JsonParty, partyInfo?: JsonPartyInfo, filter: PartiesGridFilter }

const defaultState: PartiesGridState = {
    filter: {
        ...defaultDataGridFilterState,
        orderCol: 'fullName',
        search: '',
        partyIds: [],
        eventIds: [],
        notEventIds: [],
        groupIds: [],
        partyTypes: [],
        sexType: undefined,
        isSiwi: undefined,
        isCeb: undefined,
        isPhoto: undefined,
        isUser: undefined,
        companyIds: [],
        tagIds: [],
        createdByIds: [],
        reasonSearch: '',
        formatCodeSearch: '',
        isInvited: undefined,
        siwiSyncStatuses: [],
        statuses: []
    },
};

const PartiesPage = () => {
    const t = useAppTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const codebooks = useAppSelector(selectCodebooks);
    const {user, configuration} = useAppSelector<AuthState>(selectAuthInfo);
    const [massAction, setMassAction] = useState<PartyMassActionValues<PartiesGridFilter> | undefined>(undefined);
    const [importAction, setImportAction] = useState<PartiesGridFilter | undefined>(undefined);
    const [editPartyState, setEditPartyState] = useState<EditPartyState | undefined>(undefined);
    const editPartyFilter = editPartyState?.filter;

    const parties = useAppSelector(selectParties);
    const defaultEventId = configuration?.defaultEvent?.eventId as number;
    const defaultReplyUntilOrg = configuration?.defaultEvent?.eventData?.replyUntilOrg;
    const defaultReplyUntilGuest = configuration?.defaultEvent?.eventData?.replyUntilGuest;

    const handleFetchItems = useCallback(async (filter: PartiesGridFilter) => {
        await dispatch(fetchParties(filter));
        await dispatch(fetchPartiesCount(filter as any as GetPartyCountUsingGETRequest));
    }, [dispatch]);

    const handleMassAction = useCallback((action: JsonEventPartyMassActionRequestActionEnum, filter: PartiesGridFilter, items: JsonPartyInfo[] | true) => {
        const f: PartiesGridFilter = items === true
            ? {...filter, rows: 500, start: 0}
            : {...filter, rows: 500, start: 0, partyIds: items.map((item) => item.partyId as number)};
        dispatch(fetchPartiesOnline(f)).then((res) => {
            const parties = getApiResult<JsonPartyInfo[]>(res) || [];
            setMassAction(createMassAction(action, defaultEventId, action === JsonEventPartyMassActionRequestActionEnum.InviteGuest
                ? defaultReplyUntilGuest
                : defaultReplyUntilOrg, filter, parties));
        });
    }, [defaultEventId, defaultReplyUntilOrg, defaultReplyUntilGuest, dispatch]);

    const handleSaveMassAction = useCallback(async (massAction: PartyMassActionValues<PartiesGridFilter>) => {
        setMassAction(undefined);
        handleFetchItems(massAction.filter).then();
    }, [handleFetchItems]);

    const handleCancelMassAction = useCallback(() => {
        setMassAction(undefined);
    }, []);

    const handleImportAction = useCallback((filter: PartiesGridFilter) => {
        setImportAction(filter)
    }, []);

    const handleSaveImport = useCallback(async (result: EventPartyImportResult, filter: PartiesGridFilter) => {
        setImportAction(undefined);
        handleFetchItems(filter).then();
    }, [handleFetchItems]);

    const handleCancelImport = useCallback(() => {
        setImportAction(undefined);
    }, []);

    const handleEditParty = useCallback(async (partyInfo: JsonPartyInfo | undefined, filter: PartiesGridFilter) => {
        const state: EditPartyState = {party: {partyType: (partyInfo?.partyType || JsonPartyPartyTypeEnum.Fo) as JsonPartyPartyTypeEnum}, partyInfo, filter};
        if (partyInfo?.partyId) {
            const p = getApiResult<JsonParty>(await dispatch(fetchParty(partyInfo.partyId)));
            if (p) {
                state.party = p;
            }
        }
        setEditPartyState(state);
    }, [dispatch]);

    const handleSaveParty = useCallback(async (item: JsonParty) => {
        if (editPartyFilter) {
            await handleFetchItems(editPartyFilter);
        }
        setEditPartyState(undefined);

    }, [editPartyFilter, handleFetchItems])

    const handleCancelParty = useCallback(() => {
        setEditPartyState(undefined);
    }, [])

    const filterFields = useCallback((props: DataGridFilterProps<PartiesGridFilter>): JSX.Element => {
        const {formProps, buttons} = props;
        return <Grid container spacing={1} columns={36}>
            <Grid item xs={6}>
                <TextFormField name="search" type={'text'} placeholder={'Jméno, email, poznámka, ...'} onBlur={formProps.submitForm} clearable/>
            </Grid>
            <Grid item xs={6}>
                <SelectFormField name="tagIds" placeholder={'Štítky'} onChange={formProps.submitForm}
                    codebookProps={{codebookName: 'tag', asNumber: true, addAnd: t('[+]'), addNot: t('[-]')}} isMulti={true}/>
            </Grid>
            <Grid item xs={6}>
                <SelectFormField name="companyIds" placeholder={'Firma'} onChange={formProps.submitForm}
                    codebookProps={{codebookName: 'company', asNumber: true, addEmpty: t('(bez firmy)')}} isMulti={true}/>
            </Grid>
            <Grid item xs={3}>
                <ButtonGroupField name={'isPhoto'} label={'Fotografie'} onChange={formProps.submitForm} options={isPhotoOptions} fullWidth/>
            </Grid>
            <Grid item xs={3}>
                <ButtonGroupField name={'isSiwi'} label={'Siwidata'} onChange={formProps.submitForm} options={isSiwiOptions} fullWidth/>
            </Grid>
            <Grid item xs={2}>
                <ButtonGroupField name={'sexType'} label={'Pohlaví'} onChange={formProps.submitForm} options={sexOptions} fullWidth/>
            </Grid>
            {/*<Grid item xs={3}>*/}
            {/*    <ButtonGroupField name={'isCeb'} label={'ČSB'} onChange={formProps.submitForm} options={isCebOptions} fullWidth/>*/}
            {/*</Grid>*/}
            <Grid item xs={3}>
                <ButtonGroupField name={'isUser'} label={'Uživatelský přístup'} onChange={formProps.submitForm} options={isUserOptions} fullWidth/>
            </Grid>
            <Grid item xs={4} title={t('Osobu v EMS založil')}>
                <SelectFormField name="createdByIds" placeholder={'Založil'} onChange={formProps.submitForm}
                    codebookProps={{codebookName: 'user', asNumber: true, addEmpty: t('(systém)')}} isMulti={true} shortValue/>
            </Grid>
            <Grid item xs={3}>
                {buttons}
            </Grid>

            <Grid item xs={6}>
                <SelectFormField name="eventIds" placeholder={'Je v události'} onChange={formProps.submitForm}
                    codebookProps={{codebookName: 'event', asNumber: true, sortByKeys: true, reversed: true}} isMulti={true}/>
            </Grid>
            <Grid item xs={6}>
                <SelectFormField name="notEventIds" placeholder={'Není v události'} onChange={formProps.submitForm}
                    codebookProps={{codebookName: 'event', asNumber: true, sortByKeys: true, reversed: true}} isMulti={true}/>
            </Grid>
            <Grid item xs={6}>
                <SelectFormField name="groupIds" placeholder={'Měla skupinu'} onChange={formProps.submitForm}
                    codebookProps={{
                        codebookName: 'group', asNumber: true, addExtra: [
                            createOption(-11, '(organizátoři)'), // GroupType.ORG
                            createOption(-12, '(dodavatelé)'), // GroupType.SUPP
                            createOption(-13, '(hosté)') // GroupType.GUEST
                        ]
                    }} isMulti={true}/>
            </Grid>
            <Grid item xs={3}>
                <ButtonGroupField name={'isInvited'} label={'Pozvánka'} onChange={formProps.submitForm} options={isInvitedOptions} fullWidth/>
            </Grid>
            <Grid item xs={5}>
                <TextFormField name="reasonSearch" type={'text'} placeholder={'Měla reason'} onBlur={formProps.submitForm} clearable/>
            </Grid>
            <Grid item xs={5}>
                <TextFormField name="formatCodeSearch" type={'text'} placeholder={'Měla formát'} onBlur={formProps.submitForm} clearable/>
            </Grid>
            <Grid item xs={2}>
                <ButtonGroupField name={'partyTypes'} label={'Typ osoby'} onChange={formProps.submitForm} options={partyTypeOptions} isMulti={true} fullWidth/>
            </Grid>
            <Grid item xs={3}>
                <ButtonGroupField name="siwiSyncStatuses" label={'Siwi akt.'} onChange={formProps.submitForm}
                    options={siwiSyncStatusesOptions} isMulti fullWidth iconsOnly/>
            </Grid>
        </Grid>
    }, [t]);

    const cols = useMemo((): DataGridCol<JsonPartyInfo, PartiesGridFilter>[] => [
        createCol('ID', 'partyId', '30C', 'EMS ID', (v) => <pre title={v as any as string}>{shortId(v)}</pre>),
        createCol('SIWI', 'siwiId', 40, 'Siwidata ID', (v, item) => <SiwiStatusDetail item={item} short/>),
        // createCol('ČSB', 'cebId', 70, 'ID člena', (v) => <pre>{v}</pre>),
        createCol('Jméno', 'fullName', 150, undefined, (v, item) => <Link underline={'hover'} to={`/parties/${item.partyId}`} component={RouterLink}>{partyName(item)}</Link>),
        createCol('P', 'sex', 15, 'Pohlaví', (v) => v ? <small><CodebookValue value={v} name={'sex'} formatValue={(v) => v[0]}/></small> : null),
        createCol('R', 'birthDate', '35C', 'Rok narození', (v) => v ? <small>{dateToGuiAs(v, 'Y')}</small> : null),
        {
            title: 'F',
            tooltip: 'Fotografie',
            size: 20,
            align: "center",
            col: 'photoGuid',
            renderValue: (v, item, filter) => {
                return item.partyType === JsonPartyInfoPartyTypeEnum.T ? null : <PartyPhotoTooltip photoGuid={v}/>
            }
        },
        createCol('Email', 'email', 180, undefined, (v) => <small>{v}</small>),
        // createCol('Org', 'orgCompanyName', 90, 'Organizace'),
        createCol('Štítky / Firma', 'tags', 175, undefined, (v, item) => {
            const items = [];
            items.push(<PartyTags key={'tags'} tags={v} partyId={item.partyId} quickEditTagId={FIXED_INVITE_TAG_ID}/>);
            if (item.companyId) {
                items.push(<small style={v ? {display: 'inline-block', marginTop: '10px'} : undefined} key={'company'}><CodebookValue value={item.companyId} name={'company'}/></small>)
            }
            return items;
        }),
        {
            title: 'Poznámka',
            size: 190,
            col: 'note',
            renderValue: (v, item, filter) => <small>{v}</small>
        },
        {
            title: 'Skupiny',
            size: 100,
            col: 'eventDetails',
            renderValue: (v, item, filter) => <EventDetails col={'groupId'} eventDetails={v} filter={filter}/>
        },
        {
            title: 'Reasons',
            size: 150,
            col: 'eventDetails',
            renderValue: (v, item, filter) => <EventDetails col={'reason'} eventDetails={v} filter={filter}/>
        },
        {
            title: 'Formáty',
            size: 80,
            col: 'eventDetails',
            renderValue: (v, item, filter) => <EventDetails col={'formatCode'} eventDetails={v} filter={filter}/>
        },
        createCol('U', 'userId', '30C', 'Má uživatelský účet', (v, item) => v
            ? <Tooltip title={<CodebookValue value={item.roleId} name={'role'}/>}><PersonRounded/></Tooltip> : null),
        createCol('Z', 'createdAt', '45C', 'Osoba založena', (v, item) =>
            <small title={datetimeToGui(v) + ', ' + (item.createdBy ? getCodebookLabel(codebooks, 'user', item.createdBy) : t('Systém'))}>
                {dateToGuiAs(v, 'd.M. Y', true)}</small>),

    ], [codebooks, t]);

    const actions: DataGridItemAction<JsonPartyInfo, PartiesGridFilter>[] = useMemo(() => [
        {
            title: 'Upravit',
            callback: handleEditParty,
            icon: <EditRounded/>
        },
        {
            title: 'Pozvat jako hosta',
            callback: (item: JsonPartyInfo, filter) => handleMassAction(JsonEventPartyMassActionRequestActionEnum.InviteGuest, filter, [item]),
            icon: <WorkspacePremiumRounded/>,
            isApplicable: (item) => !!item.email
        },
        {
            title: 'Pozvat jako organizátora',
            callback: (item: JsonPartyInfo, filter) => handleMassAction(JsonEventPartyMassActionRequestActionEnum.InviteOrg, filter, [item]),
            icon: <ManageAccountsRounded/>,
            isApplicable: (item) => !!item.email
        },
        {
            title: 'Pozvat jako dodavatele',
            callback: (item: JsonPartyInfo, filter) => handleMassAction(JsonEventPartyMassActionRequestActionEnum.InviteSupp, filter, [item]),
            icon: <InterpreterModeRounded/>,
            isApplicable: (item) => !!item.email
        },
        {
            title: 'Rovnou přidat do události',
            callback: (item: JsonPartyInfo, filter) => handleMassAction(JsonEventPartyMassActionRequestActionEnum.Register, filter, [item]),
            icon: <EventAvailableRounded/>
        }
    ], [handleEditParty, handleMassAction]);

    const massActions = useMemo(() => [
        {
            action: JsonEventPartyMassActionRequestActionEnum.InviteGuest,
            title: "Pozvat jako hosty",
            callback: handleMassAction,
            icon: <WorkspacePremiumRounded/>
        }, {
            action: JsonEventPartyMassActionRequestActionEnum.InviteOrg,
            title: "Pozvat jako organizátory",
            callback: handleMassAction,
            icon: <ManageAccountsRounded/>
        }, {
            action: JsonEventPartyMassActionRequestActionEnum.InviteSupp,
            title: "Pozvat jako dodavatele",
            callback: handleMassAction,
            icon: <InterpreterModeRounded/>
        }, {
            action: JsonEventPartyMassActionRequestActionEnum.Register,
            title: "Rovnou přidat do události",
            callback: handleMassAction,
            icon: <EventAvailableRounded/>
        }
    ] as DataGridMasAction<JsonPartyInfo, PartiesGridFilter, JsonEventPartyMassActionRequestActionEnum>[], [handleMassAction]);

    const header = useCallback((filter: PartiesGridFilter) => {
        return <PageHeader title={t('Osoby')}
            subPages={[{
                icon: <WorkspacePremiumRounded/>,
                title: t('VIP k pozvání'),
                action: () => {
                    navigate('/parties?notEventIds=' + defaultEventId + '&tagIds=' + FIXED_INVITE_TAG_ID);
                }
            }, {
                icon: <ScheduleRounded/>,
                title: t('Mnou založené'),
                action: () => {
                    navigate('/parties?createdByIds=' + user?.userId + "&orderCol=partyId&orderDir=desc")
                }
            }]}
            buttons={[{
                icon: <CloudUploadOutlined/>,
                title: t('Importovat osoby'),
                action: () => handleImportAction(filter)
            }, {
                icon: <GroupAddOutlined/>,
                title: t('Nová technická osoba'),
                action: () => handleEditParty({partyType: JsonPartyInfoPartyTypeEnum.T}, filter)
            }, {
                icon: <AddCircleOutlineOutlined/>,
                title: t('Nová osoba'),
                action: () => handleEditParty(undefined, filter)
            }]}/>
    }, [user?.userId, defaultEventId, handleEditParty, handleImportAction, navigate, t])

    return (
        <Container>
            <DataGrid
                header={header}
                cols={cols}
                defaultState={defaultState}
                handleFetchItems={handleFetchItems}
                itemsState={parties}
                filterFields={filterFields}
                itemKey={'partyId'}
                massActions={massActions}
                actions={actions}
                exportPath={'party/export'}
            />
            {massAction && <PartyMassActionModal
				massAction={massAction}
				onCancel={handleCancelMassAction}
				onSave={handleSaveMassAction}
			/>}
            {importAction && <PartyImportModal
				filter={importAction}
				onCancel={handleCancelImport}
				onSave={handleSaveImport}
			/>}
            {editPartyState && <PartyModal
				type={'quick'}
				item={editPartyState.party}
				partyInfo={editPartyState.partyInfo}
				onCancel={handleCancelParty}
				onSave={handleSaveParty}
			/>}
        </Container>
    );
}

export default PartiesPage;
