import {
    GetEventPartyListUsingGETStatusesEnum,
    GetPartyListUsingGETPartyTypesEnum,
    JsonEvent,
    JsonEventCateringData,
    JsonEventCateringDay,
    JsonEventCateringDayFoodTypeEnum,
    JsonEventDataFoodServiceTypesEnum,
    JsonEventDayInfo,
    JsonEventInfo,
    JsonEventPartyInfo,
    JsonEventStatusEnum,
    JsonGroupInfo,
    JsonPartyInfo,
    JsonPlaceInfo
} from "../../generated-api";
import {useAppDispatch, useAppSelector} from "../../store";
import * as React from "react";
import {
    ClipboardEvent,
    CSSProperties,
    FocusEvent,
    Fragment,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";
import {useAppTranslation} from "../../services/i18n";
import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    Grid,
    LinearProgress,
    Link,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow, Tooltip
} from "@mui/material";
import {selectCodebooks} from "../../store/selectors";
import {foodServiceOptions} from "../../model/place";
import {dateToGuiAs} from "../../helpers/date";
import {fetchEventDays} from "../../store/eventDays";
import {getApiResult} from "../../helpers/api";
import {SxProps} from "@mui/system";
import {FAKE_VALUE_ALL, FAKE_VALUE_RESET, OptionValue} from "../../model/form";
import {AddRounded, EditRounded, HelpRounded, RedoOutlined, UndoOutlined} from "@mui/icons-material";
import {fetchPartiesOnline} from "../../store/parties";
import {fetchGroups} from "../../store/groups";
import EventCateringModal, {CateringFormValueType} from "./EventCateringModal";
import {localCompare} from "../DataGrid";
import {addDays, parseISO} from "date-fns";
import {fetchEventPartiesOnline} from "../../store/eventParties";
import {Link as RouterLink} from "react-router-dom";

export type CateringGroupType = {
    groupKey: string,
    groupType: 'group' | 'party' | 'company' | 'free'
    title: string,
    id: number
}

type RowKeyType = string;
type CellKeyType = string;
type CountValueType = number | null;
type RowType<T> = { [key in CellKeyType]: T }

type EditedType = { [key in RowKeyType]: RowType<boolean> }; // [groupKey-placeId]: [dayNo-foodServiceType: true]
type ValuesType = { [key in RowKeyType]: RowType<CountValueType> }; // [groupKey-placeId]: [dayNo-foodServiceType: count | NULL]
type TotalsType = { [key in CateringGroupType['groupType'] | 'all' | number]: RowType<number> };

type HistoryItemType = { rowKey: RowKeyType, cellKey: CellKeyType, from: CountValueType, to: CountValueType };
type RewindType = 'undo' | 'redo';

const cateringStyle = {
    height: '100%',
    '& .data-grid tbody > tr > td': {
        lineHeight: 'normal',
    },
    '& .data-grid tbody > tr > td > span': {
        padding: '4px 0px'
    },
    '& .data-grid tbody > tr > td:nth-of-type(1) > span': {
        padding: '0'
    }
}

const foodCountStyle: CSSProperties = {textAlign: 'center'};

const gridSx: SxProps = {
    '.MuiTableRow-root:not(.totals):not(.sub-totals) > .MuiTableCell-body:not(:nth-of-type(1)):not(:nth-of-type(2)):not(:nth-of-type(3))': {
        '&:hover, &:focus': {
            backgroundColor: 'white !important',
            // cursor: 'text'
        }
    },
    '.MuiTableRow-root > .MuiTableCell-body:nth-of-type(2) strong, .MuiTableRow-root > .MuiTableCell-body:nth-of-type(3) strong': {
        display: '-webkit-box',
        'WebkitBoxOrient': 'vertical',
        'WebkitLineClamp': '1',
        overflow: 'hidden'
    },
    '.MuiTableRow-root > td': {
        padding: 0,
    },
    'input': {
        width: 'calc(100% - 8px)',
        padding: '0',
        textAlign: 'center'
    },
    '.visible-hover': {
        display: 'none',
    },
    '.MuiTableCell-root:hover, .MuiTableCell-root:focus': {
        position: 'relative',
        '.visible-hover': {
            display: 'flex',
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translateX(-50%) translateY(-50%)',
            zIndex: 100
        },
        '.hidden-hover': {
            opacity: .5
        }
    },
    'tbody .MuiTableCell-root > span': {
        '& > .MuiButton-root': {
            // fontSize: '90%',
            textTransform: 'none'
            // paddingTop: '2px',
            // paddingBottom: '1px',
        }
    },
    '.MuiTableRow-root.totals > .MuiTableCell-root': {
        backgroundColor: '#ffcc0066 !important',
    },
    '.MuiTableRow-root.sub-totals > .MuiTableCell-root': {
        // backgroundColor: '#ffcc0066 !important',
    },
}

function handleFocus(event: FocusEvent) {
    (event as any).target?.select();
}

const getRowKey = (group: CateringGroupType, place: JsonPlaceInfo): RowKeyType => {
    return group.groupKey + ':' + place.placeId;
}

const getCellKey = (d: JsonEventDayInfo, fs: OptionValue) => {
    return d.dayNo + ':' + fs.value;
}

const safeCountValue = (inputValue?: string): CountValueType => {
    if (inputValue?.trim()?.match(/^[0-9]+$/)) {
        return +(inputValue.trim());
    }
    return null;
}

const getColClassNames = (i: number, day: JsonEventDayInfo) => {
    return (i % 2 > 0 ? 'high-col' : '') + (day.dayNo! > 0 ? (!!day.description ? ' action-day' : '') : ' prepare-day')
}

const MatrixRow = (
    {
        eventId,
        group,
        place,
        filteredEventDays,
        foodServiceTypes,
        edited,
        handleEditClick,
        values,
        handleSetValue,
        className,
        handleEditRow,
        eventParties
    }: {
        eventId: number,
        group: CateringGroupType,
        place: JsonPlaceInfo,
        filteredEventDays: JsonEventDayInfo[],
        foodServiceTypes: OptionValue[],
        edited: RowType<boolean>,
        handleEditClick: (rowKey: RowKeyType, cellKey: CellKeyType) => void,
        values: RowType<CountValueType>,
        handleSetValue: (rowKey: RowKeyType, cellKey: CellKeyType, v: CountValueType) => void,
        className?: string,
        handleEditRow: (group: CateringGroupType, place: JsonPlaceInfo) => void,
        eventParties?: JsonEventPartyInfo[],
    }
) => {
    return useMemo(() => {
        const matchIds = group.groupType === 'group'
            ? (group.id === 1 ? [1, 18, 19, 20, 21, 22, 23] : [group.id]) // FIXME
            : undefined;
        const total = matchIds ? eventParties?.filter(ep => matchIds.indexOf(ep.groupId!) >= 0).length || 0 : 0;
        return <TableRow className={className}>
            <TableCell style={{textAlign: 'center'}}>
                <span>
                    {matchIds && <Link underline={'hover'} target={'_blank'}
                        to={`/event-parties/?eventId=${eventId}&` + matchIds.filter((a, i) => i === 0).map(id => 'groupIds=' + id).join('&')}
                        component={RouterLink}>{total}</Link>}
                </span>
            </TableCell>
            <TableCell>
                <span>
                    <strong title={group.title}>{group.title} </strong>
                </span>
            </TableCell>
            <TableCell>
                <span>
                <Button variant={'contained'} color={'inherit'} size={'small'} className={'visible-hover'}
                    onClick={() => handleEditRow(group, place)}><EditRounded/></Button>
                <strong title={place.title} className={'hidden-hover'}>{place.title}</strong>
                </span>
            </TableCell>
            {filteredEventDays?.map((day, i) => {
                return foodServiceTypes.map((fs) => {
                    const cellKey = day.dayNo + ':' + fs.value;
                    return <MatrixCell key={cellKey}
                        group={group}
                        place={place}
                        day={day}
                        cellKey={cellKey}
                        i={i}
                        isEdit={edited?.[cellKey]}
                        handleEditClick={handleEditClick}
                        value={values?.[cellKey]}
                        handleSetValue={handleSetValue}
                    />
                })
            })}
        </TableRow>;
    }, [edited, values,
        filteredEventDays, eventParties, foodServiceTypes, group, place, eventId,
        className, handleEditClick, handleSetValue, handleEditRow]);
}

const MatrixCell = ({group, place, day, cellKey, isEdit, handleEditClick, value, handleSetValue, i}: {
    group: CateringGroupType,
    place: JsonPlaceInfo,
    day: JsonEventDayInfo,
    cellKey: CellKeyType,
    isEdit: boolean,
    handleEditClick: (rowKey: RowKeyType, cellKey: CellKeyType) => void,
    value: CountValueType,
    handleSetValue: (rowKey: RowKeyType, cellKey: CellKeyType, value: CountValueType) => void,
    i: number
}) => {
    const [inputValue, setInputValue] = useState(value ? String(value) : '');
    const prevIsEdit = useRef(false);

    const handleClick = useCallback(() => {
        handleEditClick(getRowKey(group, place), cellKey);
    }, [group, place, cellKey, handleEditClick]);

    const handleChange = useCallback((e: any) => setInputValue(e.target.value), []);

    useEffect(() => {
        if (!isEdit && prevIsEdit.current) {
            setInputValue(dirtyValue => {
                const normalizedValue = safeCountValue(dirtyValue);
                handleSetValue(getRowKey(group, place), cellKey, normalizedValue);
                return normalizedValue ? String(normalizedValue) : '';
            });
        }
        prevIsEdit.current = isEdit;

    }, [isEdit, group, place, cellKey, handleSetValue]);

    useEffect(() => {
        setInputValue(value ? String(value) : '');
    }, [value]);

    return useMemo(() => {
        return <TableCell style={foodCountStyle} className={getColClassNames(i, day)} onClick={handleClick}>
            {isEdit
                ? <input type={'text'} value={inputValue} autoFocus onFocus={handleFocus} onChange={handleChange}
                    maxLength={4}/>
                : <span>{inputValue}</span>}
        </TableCell>;
    }, [inputValue, isEdit, i, day, handleClick, handleChange]);
}

const getMatrixKeys = (
    edited: EditedType,
    foodServiceTypes: OptionValue[],
    filteredEventDays: JsonEventDayInfo[],
    visibleRowKeys: RowKeyType[],
    deltaLeftRight: number,
    deltaUpDown: number
): {
    rowKey: RowKeyType,
    cellKey: CellKeyType
} | undefined => {
    const rowKey = edited ? Object.keys(edited)?.[0] : undefined;
    if (!rowKey) {
        return undefined; // nothing being edited
    }

    const nextRowKey = deltaUpDown
        ? visibleRowKeys[visibleRowKeys.findIndex(k => k === rowKey) + deltaUpDown]
        : rowKey;

    if (!nextRowKey) {
        return undefined; // moving too up/down
    }

    const currentEdit = edited[rowKey];
    const [dayNo, fs] = Object.keys(currentEdit)[0].split(':');

    let nextDayIndex = filteredEventDays?.findIndex(d => d.dayNo === +dayNo);
    let nextFsIndex = foodServiceTypes.findIndex(o => o.value === fs) + deltaLeftRight;

    if (nextFsIndex < 0) {
        nextDayIndex--;
        if (nextDayIndex < 0) {
            return undefined; // moving too left
        }
        nextFsIndex = foodServiceTypes.length - 1;

    } else if (nextFsIndex > foodServiceTypes.length - 1) {
        nextDayIndex += Math.floor(nextFsIndex / foodServiceTypes.length);
        if (nextDayIndex > filteredEventDays.length - 1) {
            return undefined; // moving too right
        }
        nextFsIndex = nextFsIndex % foodServiceTypes.length;
    }

    return {
        rowKey: nextRowKey,
        cellKey: filteredEventDays[nextDayIndex].dayNo + ':' + foodServiceTypes[nextFsIndex].value
    };
}

const EventCateringMatrix = (props: { event: JsonEventInfo, onSaveEvent: (event: JsonEvent) => Promise<void> }) => {
    const {event, onSaveEvent} = props;
    const {eventId} = event;

    const dispatch = useAppDispatch();
    const t = useAppTranslation();
    const codebooks = useAppSelector(selectCodebooks);

    const [filteredEventDays, setFilteredEventDays] = useState<JsonEventDayInfo[]>();
    const [eventParties, setEventParties] = useState<JsonEventPartyInfo[]>();

    const [availablePlaces, setAvailablePlaces] = useState<JsonPlaceInfo[]>([]);
    const [availableGroups, setAvailableGroups] = useState<CateringGroupType[]>();
    const [visibleRowKeys, setVisibleRowKeys] = useState<RowKeyType[]>([]);
    const [editCatering, setEditCatering] = useState<CateringFormValueType>();

    const [edited, setEdited] = useState<EditedType>({});
    const [values, setValues] = useState<ValuesType>({});

    const [undoStack, setUndoStack] = useState<HistoryItemType[][]>([]);
    const [redoStack, setRedoStack] = useState<HistoryItemType[][]>([]);
    const [isSaving, setIsSaving] = useState(false);

    const clipboard = useRef<string>();
    const eventHash = useRef<string>();

    const setupGroups = useCallback(async () => {
        if (!filteredEventDays || !codebooks.company?.length) {
            return;
        }
        const hash = event.cateringData ? JSON.stringify(event.cateringData) : '{}';
        if (eventHash.current === hash) {
            return;
        }
        eventHash.current = hash;

        const groups = getApiResult<JsonGroupInfo[]>(await dispatch(fetchGroups({
            orderCol: "title"
        })))?.filter((g, _, arr) => g.groupType !== 'GUEST'
            && !arr.find(p => p.settings?.managedGroupIds && p.settings.managedGroupIds.indexOf(g.groupId!) >= 0));

        const parties = getApiResult<JsonPartyInfo[]>(await dispatch(fetchPartiesOnline({
            orderCol: 'companyName',
            partyTypes: [GetPartyListUsingGETPartyTypesEnum.T]
        })));

        const eventParties = getApiResult<JsonEventPartyInfo[]>(await dispatch(fetchEventPartiesOnline({
            eventId,
            groupIds: [FAKE_VALUE_ALL],
            statuses: [GetEventPartyListUsingGETStatusesEnum.Active],
            rows: 10000
        })));

        const items: CateringGroupType[] = [];
        groups?.forEach(g => {
            items.push({
                groupKey: 'g' + g.groupId,
                groupType: 'group',
                title: g.title!,
                id: g.groupId!
            });
        });

        parties?.forEach((p) => {
            items.push({
                groupKey: 'p' + p.partyId,
                groupType: 'party',
                title: p.companyName || p.lastName || '' + p.partyId,
                id: p.partyId!
            });
        })

        codebooks.company.forEach(c => {
            items.push({
                groupKey: 'c' + c.value,
                groupType: 'company',
                title: c.label!,
                id: +c.value!
            })
        });

        const visibleGroupKeys: RowKeyType[] = [];
        const values: ValuesType = {};
        if (event.cateringData?.items?.length) {
            let freeId = 1;
            event.cateringData.items.forEach((item, i) => {
                if (!item.foodCnt) {
                    return;
                }
                const groupKey = item.groupId ? 'g' + item.groupId : (
                    item.partyId ? 'p' + item.partyId : (
                        item.companyId ? 'c' + item.companyId : 'f' + item.freeTitle
                    )
                );
                const rowKey = groupKey + ':' + item.placeId;

                if (!values[rowKey]) {
                    values[rowKey] = {};
                    visibleGroupKeys.push(rowKey);
                }
                const cellKey = filteredEventDays.find(d => d.eventDayId === item.eventDayId)?.dayNo + ':' + item.foodType;
                values[rowKey][cellKey] = item.foodCnt;

                if (item.freeTitle && !items.find(other => other.groupKey === 'f' + item.freeTitle)) {
                    items.push({
                        groupKey: 'f' + item.freeTitle,
                        groupType: 'free',
                        title: item.freeTitle,
                        id: freeId
                    });
                    freeId++;
                }
            })
        }

        setAvailableGroups(items);
        setVisibleRowKeys(visibleGroupKeys);
        setValues(values);
        setEventParties(eventParties);

    }, [codebooks.company, eventHash, event.cateringData, filteredEventDays, eventId, dispatch]);

    const handleCopy = useCallback((_: ClipboardEvent) => {
        clipboard.current = window.getSelection()?.toString();
    }, []);

    const handleEditClick = useCallback((rowKey: RowKeyType, cellKey: CellKeyType) => {
        setEdited(e => {
            if (e[rowKey]?.[cellKey]) {
                return e;
            }
            return {[rowKey]: {[cellKey]: true}}; // exclusive edit for now
        })
    }, [])

    const foodServiceTypes = useMemo(() => {
        return foodServiceOptions.filter(o => !event.eventData?.foodServiceTypes
            || event.eventData.foodServiceTypes.indexOf(o.value as JsonEventDataFoodServiceTypesEnum) >= 0);
    }, [event.eventData?.foodServiceTypes]);

    const setRewind = useCallback((history: HistoryItemType[], rewindType?: RewindType) => {
        if (!rewindType) {
            setRedoStack([]);
        }
        if (rewindType === 'undo') {
            setRedoStack((q) => {
                return [...q.slice(q.length - 1000), history];
            });
        } else {
            setUndoStack((q) => {
                return [...q.slice(q.length - 1000), history];
            });
        }

    }, []);

    const actionSetValue = useCallback((newValues: ValuesType, rewindType?: RewindType) => {
        if (!filteredEventDays) {
            return;
        }

        setValues((values) => {
            const next = {...values};
            const history: HistoryItemType[] = [];
            let updated = false;

            for (let rowKey of Object.keys(newValues)) {
                if (!next[rowKey]) {
                    next[rowKey] = {};
                } else {
                    next[rowKey] = {...next[rowKey]};
                }

                const rowValues = newValues[rowKey];
                for (let cellKey of Object.keys(rowValues)) {
                    const value = rowValues[cellKey];
                    if (!value) {
                        if (next[rowKey][cellKey]) {
                            history.push({rowKey: rowKey, cellKey, from: null, to: next[rowKey][cellKey]});
                            delete next[rowKey][cellKey];
                            updated = true;
                        }

                    } else if (!(next[rowKey][cellKey] === value)) {
                        history.push({rowKey: rowKey, cellKey, from: value, to: next[rowKey][cellKey] || null});
                        next[rowKey][cellKey] = value;
                        updated = true;
                    }

                    // if (Object.keys(next[groupKey][placeId]).length <= 0) {
                    //     delete next[groupKey][placeId];
                    // }
                }
            }
            if (!updated) {
                return values;
            }

            if (history.length) {
                setRewind(history, rewindType);
            }
            return next;
        });
    }, [filteredEventDays, setRewind]);

    const handlePaste = useCallback((e: any) => {
        if (!filteredEventDays) {
            return;
        }

        const clipboardText = e.clipboardData.getData('text');
        if (!clipboardText?.match(/^((([0-9]+)?(\t| +)?)+\r?\n?)+$/m)) {
            return;
        }
        e.stopPropagation();
        e.preventDefault();

        const rowKey = edited ? Object.keys(edited)?.[0] : undefined;
        if (!rowKey) {
            return;
        }

        const newValues: ValuesType = {}
        const lines: string[] = clipboardText.indexOf("\n") ? clipboardText.split("\n") : clipboardText.split("\r");

        for (let j = 0; j < lines.length; j++) {
            const line = lines[j].trim();
            if (!line) {
                continue;
            }

            const tokens = line.indexOf("\t") > 0 ? line.split(/\t/) : line.split(/ +/);

            for (let i = 0; i < tokens.length; i++) {
                const value = tokens[i];
                const keys = getMatrixKeys(edited, foodServiceTypes, filteredEventDays, visibleRowKeys, i, j);
                if (!keys) {
                    break;
                }
                if (!newValues[keys.rowKey]) {
                    newValues[keys.rowKey] = {};
                }
                newValues[keys.rowKey][keys.cellKey] = +value || null;
            }
        }

        actionSetValue(newValues);

    }, [edited, actionSetValue, foodServiceTypes, filteredEventDays, visibleRowKeys]);

    const handleSetValue = useCallback((rowKey: RowKeyType, cellKey: CellKeyType, value: CountValueType) => {
        actionSetValue({[rowKey]: {[cellKey]: value}});
    }, [actionSetValue]);

    const handleEditRow = useCallback((group: CateringGroupType, place: JsonPlaceInfo) => {
        setEditCatering({
            group,
            placeId: place.placeId,
            groupIds: group.groupType === 'group' ? [group.id] : undefined,
            partyIds: group.groupType === 'party' ? [group.id] : undefined,
            companyIds: group.groupType === 'company' ? [group.id] : undefined,
            freeTitles: group.groupType === 'free' ? [group.title] : undefined,
        });
    }, []);

    const setVisibleRowKeysSorted = useCallback((newIds: RowKeyType[], nextAvailableGroups: CateringGroupType[]) => {
        setVisibleRowKeys(current => {
            const next = [
                ...current,
                ...newIds,
            ]
            const sorted: string[] = [];
            nextAvailableGroups.forEach((group) => {
                availablePlaces.forEach((place) => {
                    const rowKey = getRowKey(group, place);
                    if (next.indexOf(rowKey) < 0) {
                        return null;
                    }
                    sorted.push(rowKey);
                });
            });

            return sorted;
        });
    }, [availablePlaces]);

    const rewindLastAction = useCallback((isRedo: boolean) => {
        (isRedo ? setRedoStack : setUndoStack)((history) => {
            if (!history.length) {
                return [];
            }
            const last = history[history.length - 1];
            if (last) {
                const newValues: ValuesType = {};
                for (let item of last) {
                    if (!item) {
                        continue;
                    }
                    if (!newValues[item.rowKey]) {
                        newValues[item.rowKey] = {};
                    }
                    newValues[item.rowKey][item.cellKey] = item.to;
                }
                actionSetValue(newValues, isRedo ? 'redo' : 'undo');
                if (Object.keys(newValues) && availableGroups) {
                    setVisibleRowKeysSorted(Object.keys(newValues), availableGroups);
                }
            }
            return history.filter((a) => a !== last);
        });
    }, [actionSetValue, availableGroups, setVisibleRowKeysSorted]);

    const handleSaveCateringModal = useCallback((values: CateringFormValueType) => {
        if (!availableGroups) {
            return;
        }

        if (editCatering?.placeId && editCatering.group) {
            // edit
            const oldRowId = editCatering.group.groupKey + ':' + editCatering.placeId;
            const newRowId = editCatering.group.groupKey + ':' + values.placeId;
            if (oldRowId !== newRowId) {
                setValues((next) => {
                    if (!next[oldRowId]) {
                        return next;
                    }
                    next[oldRowId] = {...next[oldRowId]};

                    const history: HistoryItemType[] = [];
                    const rowValues = next[oldRowId];
                    for (let cellKey of Object.keys(rowValues)) {
                        const value = rowValues[cellKey];
                        if (value) {
                            history.push({rowKey: oldRowId, cellKey, from: null, to: value});
                            rowValues[cellKey] = null;
                            if (values.placeId !== FAKE_VALUE_RESET) {
                                history.push({rowKey: newRowId, cellKey, from: value, to: null});
                                if (!next[newRowId]) {
                                    next[newRowId] = {};
                                } else {
                                    next[newRowId] = {...next[newRowId]}
                                }
                                next[newRowId][cellKey] = value;
                            }
                        }
                    }

                    if (history.length) {
                        setRewind(history);
                    }
                    if (values.placeId !== FAKE_VALUE_RESET) {
                        setVisibleRowKeysSorted([newRowId], availableGroups);
                    }
                    return {...next};
                });

            }
            setEditCatering(undefined);
            return;
        }

        let nextAvailableGroups = availableGroups;
        const freeGroups = availableGroups.filter(g => g.groupType === 'free');
        if (values.freeTitles) {
            values.freeTitles.forEach((freeTitle) => {
                let maxId = freeGroups.map(f => f.id).sort().reverse()[0] || 0;
                if (!availableGroups.find(g => g.groupType === 'free' && g.title === freeTitle)) {
                    maxId++;
                    freeGroups.push({
                        groupKey: 'f' + maxId,
                        groupType: 'free',
                        title: freeTitle,
                        id: maxId
                    });
                }
            });
            nextAvailableGroups = [
                ...availableGroups.filter(g => g.groupType !== 'free'), // already sorted
                ...freeGroups.sort((a, b) => localCompare(a.title, b.title))
            ];
            setAvailableGroups(nextAvailableGroups);
        }

        setVisibleRowKeysSorted([
            ...(values.groupIds?.map(id => 'g' + id + ':' + values.placeId) || []),
            ...(values.partyIds?.map(id => 'p' + id + ':' + values.placeId) || []),
            ...(values.companyIds?.map(id => 'c' + id + ':' + values.placeId) || []),
            ...(values.freeTitles?.map(title => 'f' + (freeGroups.find(g => g.title === title)?.id) + ':' + values.placeId) || []),
        ], nextAvailableGroups);

        setEditCatering(undefined);
    }, [availableGroups, editCatering, setRewind, setVisibleRowKeysSorted]);

    const handleKey = useCallback((event: KeyboardEvent) => {
        if (!filteredEventDays) {
            return;
        }
        if (event.key === 'Escape') {
            setEdited({})
        }
        if (event.key === 'ArrowRight'
            || event.key === 'ArrowLeft'
            || event.key === 'ArrowUp'
            || event.key === 'ArrowDown'
            || event.key === 'Enter'
            || event.key === 'Tab') {
            event.preventDefault();
            event.stopPropagation();
            setEdited(e => {
                const nextKeys = getMatrixKeys(e, foodServiceTypes, filteredEventDays, visibleRowKeys,
                    (event.key === 'ArrowLeft' || (event.key === 'Tab' && event.shiftKey))
                        ? -1
                        : (event.key === 'ArrowRight' || (event.key === 'Tab' && !event.shiftKey)
                            ? 1
                            : 0),
                    (event.key === 'ArrowUp' || (event.key === 'Enter' && event.shiftKey))
                        ? -1
                        : (event.key === 'ArrowDown' || (event.key === 'Enter' && !event.shiftKey)
                            ? 1
                            : 0)
                )
                return nextKeys ? {[nextKeys.rowKey]: {[nextKeys.cellKey]: true}} : e;
            })
        }
        if (event.ctrlKey && (event.key === 'y' || event.key === 'z')) {
            rewindLastAction(event.key === 'y');
        }

    }, [filteredEventDays, foodServiceTypes, visibleRowKeys, rewindLastAction]);

    const handleSaveEvent = useCallback(async () => {
        if (!availableGroups) {
            return;
        }
        setIsSaving(true);
        const cateringData: JsonEventCateringData = {items: []}

        availableGroups.forEach((group) => {
            availablePlaces.forEach((place, j) => {
                const rowKey = getRowKey(group, place);
                const i = visibleRowKeys.indexOf(rowKey);
                if (i < 0) {
                    return null;
                }
                filteredEventDays?.forEach((d) => {
                    foodServiceTypes.forEach((fs) => {
                        const value = values[rowKey]?.[getCellKey(d, fs)];
                        if (!value) {
                            return;
                        }
                        const item: JsonEventCateringDay = {
                            eventDayId: d.eventDayId,

                            groupId: group.groupType === 'group' ? group.id : undefined,
                            partyId: group.groupType === 'party' ? group.id : undefined,
                            companyId: group.groupType === 'company' ? group.id : undefined,
                            freeTitle: group.groupType === 'free' ? group.title : undefined,

                            placeId: place.placeId!,
                            foodType: fs.value! as JsonEventCateringDayFoodTypeEnum,
                            foodCnt: value
                        };
                        item.eventCateringDayId = event.cateringData?.items?.find(other => other.eventDayId === d.eventDayId
                            && other.groupId === item.groupId
                            && other.partyId === item.partyId
                            && other.companyId === item.companyId
                            && other.freeTitle === item.freeTitle
                            && other.placeId === item.placeId
                            && other.foodType === item.foodType
                        )?.eventCateringDayId;
                        cateringData.items?.push(item);
                    });
                });
            });
        });

        await onSaveEvent({
            ...event,
            allowedActions: undefined,
            status: (event.status as unknown) as JsonEventStatusEnum,
            cateringData
        }); // will refresh "event"
        setIsSaving(false);

    }, [event, values, availableGroups, availablePlaces, filteredEventDays, foodServiceTypes, visibleRowKeys, onSaveEvent]);

    const rows = useMemo(() => {
        if (!filteredEventDays || !availableGroups || !eventId) {
            return null;
        }

        const totals: TotalsType = {
            group: {},
            party: {},
            company: {},
            free: {},
            all: {}
        };
        availablePlaces.forEach((place, j) => {
            totals[place.placeId!] = {};
        });
        const totalTypes: { [key in string]: { groupType: CateringGroupType['groupType'], title: string } } = {
            g: {groupType: 'group', title: 'Pracovní skupiny'},
            p: {groupType: 'party', title: 'Technické osoby'},
            c: {groupType: 'company', title: 'Společnosti'},
            f: {groupType: 'free', title: 'Ostatní'},
        }

        const rows: JSX.Element[] = [];

        availableGroups.forEach((group) => {
            availablePlaces.forEach((place) => {
                const rowKey = getRowKey(group, place);
                filteredEventDays?.forEach((d) => {
                    return foodServiceTypes.forEach((fs) => {
                        if (!d.dayNo) {
                            return;
                        }
                        const k = getCellKey(d, fs);
                        if (!totals.all[k]) {
                            totals.all[k] = 0;
                        }
                        totals.all[k] += values[rowKey]?.[k] || 0;

                        if (!totals[place.placeId!][k]) {
                            totals[place.placeId!][k] = 0;
                        }
                        totals[place.placeId!][k] += values[rowKey]?.[k] || 0;

                        if (!totals[group.groupType][k]) {
                            totals[group.groupType][k] = 0;
                        }
                        totals[group.groupType][k] += values[rowKey]?.[k] || 0;
                    })
                });
                const i = visibleRowKeys.indexOf(rowKey);
                if (i < 0) {
                    return null;
                }

                rows.push(<MatrixRow key={rowKey}
                    eventId={eventId}
                    group={group}
                    place={place}
                    filteredEventDays={filteredEventDays}
                    foodServiceTypes={foodServiceTypes}
                    edited={edited[rowKey]}
                    handleEditClick={handleEditClick}
                    values={values[rowKey]}
                    handleSetValue={handleSetValue}
                    handleEditRow={handleEditRow}
                    eventParties={eventParties}
                    // className={i > 0 && rowKey[0] !== visibleRowKeys[i - 1][0] ? 'data-grid-separator-row' : undefined}
                />);

                if (i >= visibleRowKeys.length - 1 || rowKey[0] !== visibleRowKeys[i + 1][0]) {
                    const totalGroup = totalTypes[rowKey[0]];
                    rows.push(<TableRow key={'totals-' + totalGroup.groupType} className={'totals'}>
                        <TableCell></TableCell>
                        <TableCell colSpan={2}>
                            <span><strong>∑ {totalGroup.title}</strong></span>
                        </TableCell>
                        {filteredEventDays?.map((d, i) => {
                            if (!d.dayNo) {
                                return null;
                            }
                            return foodServiceTypes.map((fs) => {
                                const k = getCellKey(d, fs);
                                const total = totals[totalGroup.groupType][k] || 0;
                                return <Fragment key={k}>
                                    <TableCell style={foodCountStyle} className={getColClassNames(i, d)}><span>
                                <strong>{total}</strong>
                            </span>
                                    </TableCell>
                                </Fragment>;
                            });
                        })}
                    </TableRow>);
                }
            })
        });


        rows.push(<TableRow key={'total'} className={'totals'}>
            <TableCell>

            </TableCell>
            <TableCell>
                <span><strong>∑ {t('Vše')}</strong></span>
            </TableCell>
            <TableCell>
                <span>
                    <Button variant={'text'} color={'inherit'} size={'small'} onClick={() => setEditCatering({})}><AddRounded/> {t('Přidat řádky')}</Button>
                </span>
            </TableCell>
            {filteredEventDays?.map((d, i) => {
                if (!d.dayNo) {
                    return null;
                }
                return foodServiceTypes.map((fs) => {
                    const k = getCellKey(d, fs);
                    const total = totals.all[k] || 0;
                    return <Fragment key={k}>
                        <TableCell style={foodCountStyle} className={getColClassNames(i, d)}><span>
                                <strong>{total}</strong>
                            </span>
                        </TableCell>
                    </Fragment>;
                });
            })}
        </TableRow>);

        availablePlaces.forEach((place, j) => {
            const placeTotals = totals[place.placeId!];
            if (!Object.keys(placeTotals).find(k => !!placeTotals[k])) {
                return;
            }
            rows.push(<TableRow key={'totals-' + place.placeId} className={'sub-totals'}>
                <TableCell></TableCell>
                <TableCell colSpan={2}>
                    <span><strong>∑ {place.title}</strong></span>
                </TableCell>
                {filteredEventDays?.map((d, i) => {
                    if (!d.dayNo) {
                        return null;
                    }
                    return foodServiceTypes.map((fs) => {
                        const k = getCellKey(d, fs);
                        const total = placeTotals[k] || 0;
                        return <Fragment key={k}>
                            <TableCell style={foodCountStyle} className={getColClassNames(i, d)}><span>
                                <strong>{total}</strong>
                            </span>
                            </TableCell>
                        </Fragment>;
                    });
                })}
            </TableRow>);
        });


        const foodStyle: CSSProperties = {
            width: ((90 / (filteredEventDays.length + foodServiceTypes.length)) / 2) + '%',
            textAlign: 'center'
        };

        return <Grid item xs={12}>
            <Card sx={cateringStyle}>
                <CardContent>
                    <Table className={'data-grid'} sx={gridSx}>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{width: '40px'}} rowSpan={3}></TableCell>
                                <TableCell style={{width: '120px'}} rowSpan={3}><span>{t('Skupina osob')}</span></TableCell>
                                <TableCell style={{width: '120px'}} rowSpan={3}><span>{t('Místo')}</span></TableCell>
                                {filteredEventDays?.map((d, i) => {
                                    return <TableCell key={d.dayNo} style={{
                                        width: (80 / filteredEventDays.length) + '%',
                                        textAlign: 'center'
                                    }}
                                        colSpan={foodServiceTypes.length} className={getColClassNames(i, d)}>
                                        {!!d.description
                                            ? <p style={{
                                                whiteSpace: 'pre-line',
                                                padding: '4px',
                                                display: 'block',
                                                color: 'gray',
                                                fontWeight: 'normal'
                                            }}>{d.description}</p>
                                            : (d.dayNo! < 0 ? <p style={{
                                                padding: '4px',
                                                display: 'block',
                                                color: 'gray',
                                                fontWeight: 'normal'
                                            }}>{t('Příprava')}</p> : null)}
                                    </TableCell>;
                                })}
                            </TableRow>
                            <TableRow>
                                {filteredEventDays?.map((d, i) => {
                                    return <TableCell key={d.dayNo} style={{
                                        textAlign: 'center'
                                    }}
                                        colSpan={foodServiceTypes.length} className={getColClassNames(i, d)}>
                                        <span>{dateToGuiAs(d.dayDate, "eeeee d.M.")}</span>
                                    </TableCell>;
                                })}
                            </TableRow>
                            <TableRow>
                                {filteredEventDays?.map((d, i) => {
                                    return foodServiceTypes.map((fs) => {
                                        return <Fragment key={getCellKey(d, fs)}>
                                            <TableCell style={foodStyle}
                                                className={getColClassNames(i, d)}>
                                                <span>{fs.label}</span>
                                            </TableCell>
                                        </Fragment>;
                                    })
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows}
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>
        </Grid>
    }, [values, edited, visibleRowKeys, handleEditClick, handleSetValue,
        availableGroups, filteredEventDays, eventParties, foodServiceTypes, handleEditRow, availablePlaces, eventId, t]);

    useEffect(() => {
        dispatch(fetchEventDays({eventId})).then((res) => {
            const eventDays: JsonEventDayInfo[] = [];
            if (event.prepareFrom && event.prepareTo) {
                let i = -1;
                for (let pd = parseISO(event.prepareFrom); pd <= parseISO(event.prepareTo); pd = addDays(pd, 1)) {
                    eventDays.push({
                        eventDayId: i,
                        dayNo: i--,
                        dayDate: dateToGuiAs(pd, "yyyy-MM-dd")
                    })
                }
            }
            getApiResult<JsonEventDayInfo[]>(res)?.forEach(ed => eventDays.push(ed));
            setFilteredEventDays(eventDays);
        });
    }, [eventId, event.prepareFrom, event.prepareTo, dispatch]);

    useEffect(() => {
        const places: JsonPlaceInfo[] = [];
        codebooks.placeFood
            ?.filter(o => o.value?.startsWith(eventId + ':'))
            .forEach((o) => {
                places.push({
                    placeId: +o.value!.split(':')[1],
                    title: o.label
                });
            });
        setAvailablePlaces(places);
    }, [eventId, codebooks.placeFood]);

    useEffect(() => {
        setupGroups().then();
    }, [setupGroups]);

    useEffect(() => {
        window.addEventListener("keydown", handleKey);
        window.addEventListener("paste", handlePaste);
        return () => {
            window.removeEventListener("keydown", handleKey);
            window.removeEventListener("paste", handlePaste);
        }
    }, [handleKey, handlePaste]);

    if (!availableGroups) {
        return <LinearProgress/>;
    }

    return <Grid container rowSpacing={2} onCopy={handleCopy}>
        <Grid item xs={12}>
            {rows}
        </Grid>
        <Grid item xs={12} sx={{paddingTop: '10px'}}>
            <Grid container>
                <Grid item xs={4} sx={{'& > button + button': {marginLeft: '10px'}}}>
                    <Button variant={'contained'} disabled={!undoStack.length}
                        title={t('O akci zpět ({{length}})', {length: undoStack.length})}
                        onClick={() => rewindLastAction(false)}><UndoOutlined/></Button>
                    <Button variant={'contained'} disabled={!redoStack.length}
                        title={t('O akci dopředu ({{length}})', {length: redoStack.length})}
                        onClick={() => rewindLastAction(true)}><RedoOutlined/></Button>
                </Grid>
                <Grid item xs={4} sx={{textAlign: 'center'}}>
                    <Tooltip title={<div style={{fontSize: '120%', padding: '10px'}}>
                        <ul>
                            <li>Je možné kopírovat hodnoty jejich vybráním na řádku a stiskem <code>CTRL+C</code></li>
                            <li>Je možné vkládat hodnoty oddělené mezerou nebo tabelátorem
                                (a příp. odřádkované) kliknutím na první buňku a stiskem <code>CTRL+V</code>
                            </li>
                            <li>Pohyb v buňkách možný šipkami a <code>TAB</code> a <code>ENTER</code> (zpět přes podržený <code>SHIFT</code>)</li>
                            <li>Akce je možné odvolat a opakovat tlačítky vlevo dole nebo <code>CTRL+Z</code> a <code>CTRL+Y</code></li>
                            <li>Promazání řádku možné kliknutím na editaci (najetí na název místa) a výběrem volby "Promazat řádek"</li>
                            <li>Prázdné řádky budou automaticky odstraněny po uložení změn</li>
                            <li>Číslo u názvu pracovní skupiny označuje aktuální počet osob v události v dané skupině (vč. nepotvrzených), bez ohledu na jejich stravu</li>
                        </ul>
                    </div>} PopperProps={{
                        sx: {
                            '& .MuiTooltip-tooltip': {
                                maxWidth: 'clamp(200px, 400px, 80vw)'
                            },
                            'li + li': {
                                marginTop: '10px'
                            }
                        }
                    }}
                        placement={'top'}><abbr><HelpRounded/></abbr></Tooltip>
                </Grid>
                <Grid item xs={4} sx={{textAlign: 'right'}}>
                    <Button variant={'contained'} disabled={isSaving} onClick={(e) => {
                        if (Object.keys(edited)?.length) {
                            setEdited({});
                            setTimeout(() => {
                                (e.target as HTMLButtonElement).click();
                            }, 1000); // FIXME
                        } else {
                            handleSaveEvent().then();
                        }
                    }}>
                        {isSaving && <CircularProgress size={20}/>}<span>{t('Uložit vše')}</span></Button>
                </Grid>
            </Grid>
        </Grid>
        {!!editCatering && <EventCateringModal
            item={editCatering}
            visibleGroupKeys={visibleRowKeys}
            availableGroups={availableGroups}
            eventId={eventId!}
            saveButtonTitle={editCatering.placeId ? t('Uložit změny') : t('Přidat vybrané')}
            onSave={handleSaveCateringModal}
            onCancel={() => setEditCatering(undefined)}
        />}
    </Grid>
}

export default EventCateringMatrix;
