/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonEventGroupSettings,
    JsonEventGroupSettingsFromJSON,
    JsonEventGroupSettingsFromJSONTyped,
    JsonEventGroupSettingsToJSON,
} from './JsonEventGroupSettings';
import {
    JsonGroupSettings,
    JsonGroupSettingsFromJSON,
    JsonGroupSettingsFromJSONTyped,
    JsonGroupSettingsToJSON,
} from './JsonGroupSettings';

/**
 * 
 * @export
 * @interface JsonEventGroupInfo
 */
export interface JsonEventGroupInfo {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonEventGroupInfo
     */
    allowedActions?: Array<JsonEventGroupInfoAllowedActionsEnum>;
    /**
     * 
     * @type {string}
     * @memberof JsonEventGroupInfo
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventGroupInfo
     */
    createdBy?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventGroupInfo
     */
    eventGroupId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventGroupInfo
     */
    eventId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventGroupInfo
     */
    eventPartyCount?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventGroupInfo
     */
    groupId?: number;
    /**
     * 
     * @type {JsonGroupSettings}
     * @memberof JsonEventGroupInfo
     */
    groupSettings?: JsonGroupSettings;
    /**
     * 
     * @type {string}
     * @memberof JsonEventGroupInfo
     */
    groupType?: JsonEventGroupInfoGroupTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonEventGroupInfo
     */
    notifLastAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventGroupInfo
     */
    notifPartyCount?: number;
    /**
     * 
     * @type {JsonEventGroupSettings}
     * @memberof JsonEventGroupInfo
     */
    settings?: JsonEventGroupSettings;
    /**
     * 
     * @type {string}
     * @memberof JsonEventGroupInfo
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventGroupInfo
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventGroupInfo
     */
    updatedBy?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonEventGroupInfoAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}/**
* @export
* @enum {string}
*/
export enum JsonEventGroupInfoGroupTypeEnum {
    Org = 'ORG',
    Supp = 'SUPP',
    Guest = 'GUEST'
}

export function JsonEventGroupInfoFromJSON(json: any): JsonEventGroupInfo {
    return JsonEventGroupInfoFromJSONTyped(json, false);
}

export function JsonEventGroupInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonEventGroupInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'eventGroupId': !exists(json, 'eventGroupId') ? undefined : json['eventGroupId'],
        'eventId': !exists(json, 'eventId') ? undefined : json['eventId'],
        'eventPartyCount': !exists(json, 'eventPartyCount') ? undefined : json['eventPartyCount'],
        'groupId': !exists(json, 'groupId') ? undefined : json['groupId'],
        'groupSettings': !exists(json, 'groupSettings') ? undefined : JsonGroupSettingsFromJSON(json['groupSettings']),
        'groupType': !exists(json, 'groupType') ? undefined : json['groupType'],
        'notifLastAt': !exists(json, 'notifLastAt') ? undefined : json['notifLastAt'],
        'notifPartyCount': !exists(json, 'notifPartyCount') ? undefined : json['notifPartyCount'],
        'settings': !exists(json, 'settings') ? undefined : JsonEventGroupSettingsFromJSON(json['settings']),
        'title': !exists(json, 'title') ? undefined : json['title'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
    };
}

export function JsonEventGroupInfoToJSON(value?: JsonEventGroupInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'eventGroupId': value.eventGroupId,
        'eventId': value.eventId,
        'eventPartyCount': value.eventPartyCount,
        'groupId': value.groupId,
        'groupSettings': JsonGroupSettingsToJSON(value.groupSettings),
        'groupType': value.groupType,
        'notifLastAt': value.notifLastAt,
        'notifPartyCount': value.notifPartyCount,
        'settings': JsonEventGroupSettingsToJSON(value.settings),
        'title': value.title,
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
    };
}

