import {useAppDispatch} from "../store";
import * as React from "react";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {JsonApiEventParty} from "../generated-api";
import {Alert, Card, CardContent, CardHeader, CircularProgress, Container} from "@mui/material";
import {useAppTranslation} from "../services/i18n";
import {CheckOutlined, ClearOutlined, QrCodeScannerOutlined} from "@mui/icons-material";
import {dateToGuiAs} from "../helpers/date";
import {fetchEventPartyByBarcode} from "../store/rsvp";
import {getApiResult} from "../helpers/api";

type ScannerLog = {
    timestamp: Date,
    barcode: string;
    state: 'loading' | 'ok' | 'error'
    party?: JsonApiEventParty,
    error?: string
}

type ScannerState = 'waiting' | 'scanning' | 'scanned'

const AmmoPage = () => {
    const t = useAppTranslation();
    const dispatch = useAppDispatch();

    const [isActive, setIsActive] = useState(true);
    const [scannerState, setScannerState] = useState<ScannerState>('waiting');
    const [logItems, setLogItems] = useState<ScannerLog[]>([]);

    const fragment = useRef('');

    const handleFocus = useCallback(() => {
        fragment.current = '';
        setIsActive(true);
    }, []);

    const handleBlur = useCallback(() => {
        setIsActive(false);
    }, []);

    const handleKey = useCallback((event: KeyboardEvent) => {
        if (event.key === 'Enter' || event.key === 'Escape' || event.key === 'Shift') {
            event.preventDefault();
            event.stopPropagation();

            if (event.key === 'Enter' && !!fragment.current?.trim()) {
                const item: ScannerLog = {
                    timestamp: new Date(),
                    barcode: fragment.current,
                    state: 'loading'
                };
                setLogItems(logItems => [item, ...logItems]);

                dispatch(fetchEventPartyByBarcode({
                    barcode: fragment.current,
                    type: 'ammo',
                    token: 'tcfftqwxev', // secret
                })).then((res) => {
                    const party = getApiResult<JsonApiEventParty>(res);
                    if (party) {
                        item.state = 'ok';
                        item.party = party;
                    } else {
                        item.state = 'error';
                        if ('error' in res) {
                            if (res.error.message === 'ENTITY_NOT_FOUND') {
                                item.error = t('Kód nenalezen');
                            } else {
                                item.error = res.error.message;
                            }
                        }
                    }
                    setLogItems(logItems => logItems.map(o => o === item ? item : o));
                });
                setScannerState('scanned');
                setTimeout(() => setScannerState('waiting'), 1000);
            } else {
                setScannerState('waiting');
            }

            fragment.current = '';
        }

        if (/^[A-Za-z0-9]$/.test(event.key)) {
            setScannerState('scanning');
            fragment.current += event.key;
            event.preventDefault();
        }

    }, [dispatch, t]);

    const historyItems = useMemo(() => {
        console.log("historyItems")
        return logItems?.map((item, index) => <tr key={index}>
                <th>{dateToGuiAs(item.timestamp, 'dd.MM.yyyy HH:mm:ss')}</th>
                <th><code>{item.barcode}</code></th>
                <td>{item.state === 'loading'
                    ? <div style={{position: 'relative'}}><CircularProgress size={20}/></div>
                    : (item.state === 'ok' && !!item.party
                            ? <strong>
                                {item.party.lastName} {item.party.firstName}
                                {!!item.party.reason && ` [${item.party.reason}]`}
                                {!!item.party.tags && ` (${item.party.tags})`}
                                {!!item.party.note && ` (${item.party.note})`}
                            </strong>
                            : <Alert severity={'error'} icon={false}>{item.error || t('Chyba')}</Alert>
                    )}
                </td>
            </tr>)
    }, [logItems, t]);

    useEffect(() => {
        window.addEventListener("keydown", handleKey);
        window.addEventListener("focus", handleFocus);
        window.addEventListener("blur", handleBlur);
        return () => {
            window.removeEventListener("keydown", handleKey);
            window.removeEventListener("focus", handleFocus);
            window.removeEventListener("blur", handleBlur);
        }
    }, [handleKey, handleFocus, handleBlur]);

    return <Container className={'scanner-page scanner-state-' + scannerState}>
        <Card className={'scanner-card'}>
            <CardContent>
                {isActive
                    ? (scannerState === 'scanned'
                        ? <Alert severity={'success'} icon={false}>
                            <h1>{t('Naskenováno!')}</h1>
                            <CheckOutlined/>
                        </Alert>
                        : <Alert severity={'info'} icon={false}>
                            <h1>{t('Připraveno, čeká se na čtečku ...')}</h1>
                            <QrCodeScannerOutlined/>
                        </Alert>)
                    : <Alert severity={'error'} icon={false}>
                        <h1>{t('Pro skenování klepněte do okna!')}</h1>
                        <ClearOutlined/>
                    </Alert>}
            </CardContent>
        </Card>
        {!!logItems.length && <Card className={'scanner-history'}>
            <CardHeader title={t('Historie')}/>
            <CardContent>
                <table className={'info-box-table'} style={{width: "100%"}}>
                    <tbody>
                    {historyItems}
                    </tbody>
                </table>
            </CardContent>
        </Card>}
    </Container>;
}

export default AmmoPage;
