/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JsonEventData
 */
export interface JsonEventData {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonEventData
     */
    foodServiceTypes?: Array<JsonEventDataFoodServiceTypesEnum>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof JsonEventData
     */
    groupAreas?: { [key: string]: string; };
    /**
     * 
     * @type {number}
     * @memberof JsonEventData
     */
    headerFileId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventData
     */
    kmRate?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonEventData
     */
    orgGroupText?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventData
     */
    replyUntilGuest?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventData
     */
    replyUntilOrg?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventData
     */
    replyUntilOrgComp?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventData
     */
    replyUntilOrgUpdate?: string;
}

/**
* @export
* @enum {string}
*/
export enum JsonEventDataFoodServiceTypesEnum {
    Br = 'BR',
    Lu = 'LU',
    Di = 'DI',
    Sn = 'SN'
}

export function JsonEventDataFromJSON(json: any): JsonEventData {
    return JsonEventDataFromJSONTyped(json, false);
}

export function JsonEventDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonEventData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'foodServiceTypes': !exists(json, 'foodServiceTypes') ? undefined : json['foodServiceTypes'],
        'groupAreas': !exists(json, 'groupAreas') ? undefined : json['groupAreas'],
        'headerFileId': !exists(json, 'headerFileId') ? undefined : json['headerFileId'],
        'kmRate': !exists(json, 'kmRate') ? undefined : json['kmRate'],
        'orgGroupText': !exists(json, 'orgGroupText') ? undefined : json['orgGroupText'],
        'replyUntilGuest': !exists(json, 'replyUntilGuest') ? undefined : json['replyUntilGuest'],
        'replyUntilOrg': !exists(json, 'replyUntilOrg') ? undefined : json['replyUntilOrg'],
        'replyUntilOrgComp': !exists(json, 'replyUntilOrgComp') ? undefined : json['replyUntilOrgComp'],
        'replyUntilOrgUpdate': !exists(json, 'replyUntilOrgUpdate') ? undefined : json['replyUntilOrgUpdate'],
    };
}

export function JsonEventDataToJSON(value?: JsonEventData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'foodServiceTypes': value.foodServiceTypes,
        'groupAreas': value.groupAreas,
        'headerFileId': value.headerFileId,
        'kmRate': value.kmRate,
        'orgGroupText': value.orgGroupText,
        'replyUntilGuest': value.replyUntilGuest,
        'replyUntilOrg': value.replyUntilOrg,
        'replyUntilOrgComp': value.replyUntilOrgComp,
        'replyUntilOrgUpdate': value.replyUntilOrgUpdate,
    };
}

