import {Button, Container, Grid, Link, Tooltip} from '@mui/material';
import * as React from 'react';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {TAppFunction, useAppTranslation} from '../services/i18n';
import {clearInvites, fetchInvites, fetchInvitesCount} from '../store/invites';
import {Link as RouterLink} from "react-router-dom";
import PageHeader from '../components/layout/PageHeader';
import {
    GetInviteCountUsingGETRequest,
    GetInviteListUsingGETInviteKindsEnum,
    GetInviteListUsingGETRequest,
    JsonInviteInfo,
    JsonInviteInfoInviteTypeEnum,
    JsonInviteInfoStatusEnum,
    JsonInviteReply,
    JsonInviteReplyDayReplyTypeEnum,
    JsonInviteReplyExtra,
    JsonInviteReplyExtraApprovedStatusEnum,
    JsonInviteReplyExtraInfo,
    JsonInviteReplyExtraInfoApprovedStatusEnum,
    JsonInviteReplyStatusEnum,
    JsonUserInfo
} from '../generated-api';
import {createCol, DataGrid, DataGridCol, DataGridFilter, DataGridFilterProps, DataGridItemAction, DataGridState, defaultDataGridFilterState} from "../components/DataGrid";
import {useAppDispatch, useAppSelector} from "../store";
import {selectAuthInfo, selectInvites} from "../store/selectors";
import {TextFormField} from "../components/form/TextFormField";
import {ButtonGroupField} from "../components/form/ButtonGroupField";
import {SelectFormField} from "../components/form/SelectFormField";
import CodebookValue, {OptionIcon} from "../components/CodebookValue";
import {datetimeToGui, dateToGuiAs} from "../helpers/date";
import {partyName} from "../model/party";
import {CheckBoxOutlineBlankRounded, CloseRounded, DoneOutlineRounded, DoneRounded, HourglassEmptyRounded, QuestionMarkRounded, RemoveRedEye} from "@mui/icons-material";
import InviteReviewModal from "./InviteReviewModal";
import {saveInviteReply} from "../store/inviteReplies";
import {addApiResultMessage, ApiChangeType, getApiResult} from "../helpers/api";
import EventDaysValue from "../components/EventDaysValue";
import {
    getEffectiveExtras,
    inviteExtraLimitShortOptions,
    inviteIsActiveOptions,
    inviteIsParkShortOptions,
    inviteStatusOptions,
    inviteTypeOptions,
    renderExtraName,
    renderInviteType
} from "../model/invite";
import {useNavigate} from "react-router";
import {FIXED_INVITE_TAG_ID, ROLE_ADMIN} from "../store/codebooks";
import PartyPhoto, {PartyPhotoTooltip} from "../components/party/PartyPhoto";
import {fetchInviteReplyExtra, saveInviteReplyExtra} from "../store/inviteReplyExtras";
import PartyTags from "../components/PartyTags";
import {EventDaysFilter} from "../components/EventDaysFilter";
import {AuthState} from "../store/auth";

interface InvitesGridFilter extends DataGridFilter, GetInviteListUsingGETRequest {
}

interface InvitesGridState extends DataGridState<JsonInviteInfo, InvitesGridFilter> {
}

export const shortDate = (v?: string) => {
    return <span title={datetimeToGui(v)}>{dateToGuiAs(v, 'd.M.')}</span>
}

export const createInviteActions = (t: TAppFunction, setInviteId: (inviteId?: number) => void, user?: JsonUserInfo) => {

    const isAdmin = user?.roleId === ROLE_ADMIN;
    return [
        {
            title: t('Zpracovat'),
            icon: <DoneOutlineRounded/>,
            callback: (item) => setInviteId(item.inviteId),
            isApplicable: (item) => {
                return item.status === JsonInviteInfoStatusEnum.Accepted
                    && (isAdmin || (user?.userData?.groupIds && item.groupId && user.userData.groupIds.indexOf(item.groupId) >= 0));
            }
        }, {
            title: t('Náhled'),
            icon: <RemoveRedEye/>,
            callback: (item) => setInviteId(item.inviteId),
            isApplicable: (item) => {
                return item.status !== JsonInviteInfoStatusEnum.Accepted
                    && (isAdmin || (user?.userData?.groupIds && item.groupId && user.userData.groupIds.indexOf(item.groupId) >= 0));
            }
        }
    ] as DataGridItemAction<JsonInviteInfo>[];
}

type ReplyExtraProps = {
    item: JsonInviteInfo
}

export const ReplyExtra = (props: ReplyExtraProps) => {
    const {item} = props;
    const {replyExtras, eventId} = item;
    const isApprovable = item.status === JsonInviteInfoStatusEnum.Accepted;
    const showApprove = item.inviteType === JsonInviteInfoInviteTypeEnum.Guest;

    const t = useAppTranslation();
    const dispatch = useAppDispatch();
    const [myExtras, setMyExtras] = useState<JsonInviteReplyExtraInfo[] | undefined>();

    const handleSaveApprove = useCallback(async (replyExtraId: number | undefined, approvedStatus: JsonInviteReplyExtraApprovedStatusEnum | undefined) => {
        if (!replyExtraId) {
            return;
        }
        const extra = getApiResult<JsonInviteReplyExtra>(await dispatch(fetchInviteReplyExtra(replyExtraId)));
        if (!extra || extra.approvedStatus === approvedStatus) {
            return;
        }
        const saved = getApiResult<JsonInviteReplyExtra>(await dispatch(saveInviteReplyExtra({...extra, approvedStatus})));
        setMyExtras((items) => items?.map((extra) => extra.replyExtraId === saved?.replyExtraId
            ? saved as JsonInviteReplyExtraInfo
            : extra));

    }, [dispatch]);

    const extras = useMemo(() => {
        if (!myExtras || !myExtras.length) {
            return null;
        }
        return <table className={'import-match-box'}>
            <tbody>
            {myExtras.map((extra, i) => {
                const isApproved = extra.approvedStatus === JsonInviteReplyExtraInfoApprovedStatusEnum.Approved;
                const isRejected = extra.approvedStatus === JsonInviteReplyExtraInfoApprovedStatusEnum.Rejected;
                return <tr key={i}>
                    {showApprove && <td className={'approve-buttons'}>
						<Button
							color={isApproved ? 'inherit' : 'error'}
							variant={'text'}
							title={isRejected ? t('Doprovod byl označen k zamítnutí') : t('Označit doprovod k zamítnutí')}
							disabled={!isApprovable}
							onClick={() => {
                                handleSaveApprove(extra.replyExtraId, isRejected ? undefined : JsonInviteReplyExtraApprovedStatusEnum.Rejected).then();
                            }}>{isRejected ? <CloseRounded/> : <CheckBoxOutlineBlankRounded/>}
						</Button>
						<Button
							color={isRejected ? 'inherit' : 'success'}
							variant={'text'}
							title={isApproved ? t('Doprovod byl označen k přijetí') : t('Označit doprovod k přijetí')}
							disabled={!isApprovable}
							onClick={() => {
                                handleSaveApprove(extra.replyExtraId, isApproved ? undefined : JsonInviteReplyExtraApprovedStatusEnum.Approved).then();
                            }}>{isApproved ? <DoneRounded/> : <CheckBoxOutlineBlankRounded/>}
						</Button>
					</td>}
                    <td><PartyPhotoTooltip photoGuid={extra.photoGuid}
                        icon={<div><PartyPhoto photoGuid={extra.photoGuid} maxHeight={24} maxWidth={40}/></div>}
                        emptyIcon={<div><PartyPhoto photoGuid={undefined} maxHeight={24} maxWidth={40}/></div>}
                    /></td>
                    <td>{renderExtraName(extra, undefined, i, eventId)}</td>
                    <td>
                        <EventDaysValue eventDays={extra.eventDays} eventId={eventId}/>
                    </td>
                </tr>;
            })}</tbody>
        </table>;
    }, [myExtras, isApprovable, showApprove, eventId, handleSaveApprove, t])

    useEffect(() => {
        setMyExtras(replyExtras)
    }, [replyExtras]);

    if (extras) {
        if (item.inviteType === JsonInviteInfoInviteTypeEnum.Supplier) {
            return <Tooltip title={extras}>
                <span>+{myExtras?.length || 0}</span>
            </Tooltip>
        } else {
            return extras;
        }
    }
    if (!item.replyData?.replyDays) {
        return null;
    }
    const effectiveExtras = getEffectiveExtras(item.inviteData, item.replyData);
    return <>
        {effectiveExtras?.map((extra, i) => {
            return <div key={i}>{partyName(extra)}</div>;
        })}
    </>;

}

const cols: DataGridCol<JsonInviteInfo, InvitesGridFilter>[] = [
    createCol('Kód', 'token', 50, 'Kód pozvánky a odkaz na formulář', (v) => <pre><a href={'/rsvp/' + v} target={'_blank'} rel="noreferrer">{v}</a></pre>),
    createCol('Typ', 'inviteType', '40C', 'Typ pozvánky', renderInviteType),
    createCol('Pozvaná osoba', 'fullName', 120, undefined, (v, item) => <Link underline={'hover'} to={`/parties/${item.partyId}`} component={RouterLink}>{v}</Link>),
    createCol('Email', 'email', 150, undefined, (v) => <small>{v}</small>),
    createCol('Štítky / Firma', 'tags', 130, undefined, (v, item) => {
        const items = [];
        if (v) {
            items.push(<PartyTags key={'tags'} tags={v} partyId={item.partyId} quickEditTagId={FIXED_INVITE_TAG_ID}/>);
        }
        if (item.companyId) {
            items.push(<small style={v ? {display: 'inline-block', marginTop: '10px'} : undefined} key={'company'}><CodebookValue value={item.companyId} name={'company'}/></small>)
        }
        return items;
    }),
    createCol('Skupina', 'groupId', 80, 'Pracovní skupina', (v) => v ? <CodebookValue value={v} name={'group'}/> : null),
    {
        title: 'Dny',
        size: 50,
        col: 'inviteData',
        renderValue: (v, item, filter) => {
            const acceptedDays = !!item.replyData?.replyDays
                ? v?.eventDays?.filter((dayNo) => item.replyData?.replyDays?.[dayNo]?.replyType === JsonInviteReplyDayReplyTypeEnum.Accept)
                : v?.eventDays;

            return <EventDaysValue eventDays={acceptedDays} eventId={item.eventId}/>
        }
    },
    {
        title: 'Doprovod',
        size: 200,
        col: 'replyData',
        renderValue: (v, item, filter) => {
            return <ReplyExtra item={item}/>
        }
    },
    createCol('Ode', 'createdAt', 40, 'Vytvoření pozvánky', shortDate),
    createCol('Odp', 'replyAt', 40, 'Odpověď přijatá', shortDate),
    createCol('Uzá', 'replyUntil', 40, 'Odpověď do', shortDate),
    createCol('Zpr', 'processAt', 40, 'Odpověď zpracovaná', shortDate),
    // createCol('N', 'isActive', 10, 'Pozvánka je neaktivní, tj. pro danou osobu existuje novější', (v) => !v ? <Done/> : null),
    createCol('St', 'status', 30, 'Stav pozvánky', (v) => {
        return <OptionIcon value={v} options={inviteStatusOptions}/>
    }),
];

const defaultStateTemplate: InvitesGridState = {
    filter: {
        ...defaultDataGridFilterState,
        orderCol: 'createdAt',
        orderDir: 'desc',
        search: '',
        partyIds: [],
        eventId: -1,
        eventDay: undefined,
        inviteTypes: [],
        groupIds: [],
        extraLimit: undefined,
        isPark: undefined,
        companyIds: [],
        isActive: undefined,
        relPartyId: undefined,
        tagIds: [],
        statuses: [],
        inviteKinds: [GetInviteListUsingGETInviteKindsEnum.Invite]
    },
};

export const getInviteRowClassName = (item: JsonInviteInfo, filter?: InvitesGridFilter): string[] | undefined => {
    if (!item.isActive && item.status !== JsonInviteInfoStatusEnum.Confirmed) {
        return ['data-grid-inactive-row'];
    }
    return undefined;
}

const filterFields = (props: DataGridFilterProps<InvitesGridFilter>, t: TAppFunction): JSX.Element => {
    const {formProps, buttons} = props;
    return <Grid container spacing={1}>
        <Grid item xs={2}>
            <SelectFormField name="eventId" placeholder={'Událost'} onChange={formProps.submitForm}
                codebookProps={{codebookName: 'event', asNumber: true, sortByKeys: true, reversed: true}}/>
        </Grid>
        <Grid item xs={2}>
            <EventDaysFilter formProps={formProps}/>
        </Grid>
        <Grid item xs={2}>
            <ButtonGroupField name="inviteTypes" label={'Typ pozvánky'} onChange={formProps.submitForm}
                options={inviteTypeOptions} isMulti fullWidth/>
        </Grid>
        <Grid item xs={2}>
            <SelectFormField name="groupIds" placeholder={'Skupina'} onChange={formProps.submitForm}
                codebookProps={{codebookName: 'group', asNumber: true, addEmpty: t('(bez skupiny)')}} isMulti={true}/>
        </Grid>
        <Grid item xs={2}>
            <ButtonGroupField name="extraLimit" label={'Doprovod'} onChange={formProps.submitForm}
                options={inviteExtraLimitShortOptions} fullWidth/>
        </Grid>
        <Grid item xs={1}>
            <ButtonGroupField name="isPark" label={'Parkování'} onChange={formProps.submitForm}
                options={inviteIsParkShortOptions} fullWidth/>
        </Grid>
        <Grid item xs={1}>
            {buttons}
        </Grid>

        <Grid item xs={2}>
            <TextFormField name="search" type={'text'} placeholder={'Jméno, email, ...'} onBlur={formProps.submitForm} clearable/>
        </Grid>
        <Grid item xs={2}>
            <SelectFormField name="tagIds" placeholder={'Štítky'} onChange={formProps.submitForm}
                codebookProps={{codebookName: 'tag', asNumber: true, addAnd: t('[+]'), addNot: t('[-]')}} isMulti={true}/>
        </Grid>
        <Grid item xs={2}>
            <ButtonGroupField name="isActive" label={'Historické = existuje novější (tj. aktuální) verze'} onChange={formProps.submitForm}
                options={inviteIsActiveOptions} fullWidth/>
        </Grid>
        <Grid item xs={2}>
        </Grid>
        <Grid item xs={2}>
        </Grid>
        <Grid item xs={2}>
            <ButtonGroupField name="statuses" label={'Stav pozvánky'} onChange={formProps.submitForm}
                options={inviteStatusOptions} isMulti iconsOnly fullWidth/>
        </Grid>
    </Grid>
}

const InvitesPage = () => {
    const t = useAppTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {user, configuration} = useAppSelector<AuthState>(selectAuthInfo);

    const defaultState = useRef<InvitesGridState>({...defaultStateTemplate, filter: {...defaultStateTemplate.filter, eventId: configuration?.defaultEvent?.eventId}});
    const [inviteId, setInviteId] = useState<number | undefined>(undefined);
    const [currentFilter, setCurrentFilter] = useState<InvitesGridFilter>(defaultState.current.filter);

    const invites = useAppSelector(selectInvites);

    const handleFetchItems = useCallback((filter: InvitesGridFilter) => {
        setCurrentFilter(filter);
        dispatch(fetchInvitesCount(filter as any as GetInviteCountUsingGETRequest)).then((r) => {
            dispatch(fetchInvites(filter));
        });

    }, [dispatch]);

    const handleSaveReply = useCallback(async (reply: JsonInviteReply) => {
        const res = await dispatch(saveInviteReply(reply));
        const item = getApiResult<JsonInviteReply>(res);
        if (item) {
            let m: string = reply.status + '';
            switch (reply.status) {
                case JsonInviteReplyStatusEnum.Accepted:
                    m = 'Pozvánka byla schválena';
                    break;
                case JsonInviteReplyStatusEnum.Rejected:
                    m = 'Pozvánka byla vrácena k doplnění';
                    break;
                case JsonInviteReplyStatusEnum.Deleted:
                    m = 'Pozvánka byla stornována';
                    break;
                case JsonInviteReplyStatusEnum.Sent:
                    m = 'Rozpracovaná pozvánka uložena';
                    break;
            }
            addApiResultMessage(res, {
                [ApiChangeType.CREATED]: m,
                [ApiChangeType.UPDATED]: m,
                [ApiChangeType.NO_CHANGE]: m,
            }, t, dispatch);
            if (reply.status === JsonInviteReplyStatusEnum.Sent) {
                return item;
            }
            handleFetchItems(currentFilter);
            setInviteId(undefined);
        }
        return item;

    }, [handleFetchItems, currentFilter, dispatch, t]);

    const actions = useMemo(() => {
        return createInviteActions(t, setInviteId, user)
    }, [user, setInviteId, t]);

    useEffect(() => {
        return () => {
            dispatch(clearInvites());
        }
    }, [dispatch]);

    return (
        <Container>
            <PageHeader title={t('Pozvánky')}
                subPages={[{
                    icon: <HourglassEmptyRounded/>,
                    title: t('Ke schválení'),
                    action: () => {
                        navigate('/invites?isActive=true&statuses=ACCEPTED');
                    }
                }, {
                    icon: <QuestionMarkRounded/>,
                    title: t('Nezodpovězené'),
                    action: () => {
                        navigate('/invites?isActive=true&statuses=PENDING&statuses=RETURNED');
                    }
                }]}
            />

            <DataGrid
                cols={cols}
                defaultState={defaultState.current}
                handleFetchItems={handleFetchItems}
                itemsState={invites}
                filterFields={filterFields}
                actions={actions}
                itemKey={'inviteId'}
                getRowClassNames={getInviteRowClassName}
            />

            {!!inviteId && <InviteReviewModal
				inviteId={inviteId}
				onSave={handleSaveReply}
				onCancel={() => setInviteId(undefined)}/>}
        </Container>
    );
}

export default InvitesPage;
