/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonChangeOfobject,
    JsonChangeOfobjectFromJSON,
    JsonChangeOfobjectFromJSONTyped,
    JsonChangeOfobjectToJSON,
} from './JsonChangeOfobject';

/**
 * 
 * @export
 * @interface JsonEventCateringDay
 */
export interface JsonEventCateringDay {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonEventCateringDay
     */
    allowedActions?: Array<JsonEventCateringDayAllowedActionsEnum>;
    /**
     * 
     * @type {Array<JsonChangeOfobject>}
     * @memberof JsonEventCateringDay
     */
    changes?: Array<JsonChangeOfobject>;
    /**
     * 
     * @type {number}
     * @memberof JsonEventCateringDay
     */
    companyId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonEventCateringDay
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventCateringDay
     */
    createdBy?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventCateringDay
     */
    eventCateringDayId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonEventCateringDay
     */
    eventDayDate?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventCateringDay
     */
    eventDayId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventCateringDay
     */
    foodCnt?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonEventCateringDay
     */
    foodType?: JsonEventCateringDayFoodTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonEventCateringDay
     */
    freeTitle?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventCateringDay
     */
    groupId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonEventCateringDay
     */
    note?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventCateringDay
     */
    partyId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventCateringDay
     */
    placeId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonEventCateringDay
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventCateringDay
     */
    updatedBy?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonEventCateringDayAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}/**
* @export
* @enum {string}
*/
export enum JsonEventCateringDayFoodTypeEnum {
    Br = 'BR',
    Lu = 'LU',
    Di = 'DI',
    Sn = 'SN'
}

export function JsonEventCateringDayFromJSON(json: any): JsonEventCateringDay {
    return JsonEventCateringDayFromJSONTyped(json, false);
}

export function JsonEventCateringDayFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonEventCateringDay {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'changes': !exists(json, 'changes') ? undefined : ((json['changes'] as Array<any>).map(JsonChangeOfobjectFromJSON)),
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'eventCateringDayId': !exists(json, 'eventCateringDayId') ? undefined : json['eventCateringDayId'],
        'eventDayDate': !exists(json, 'eventDayDate') ? undefined : json['eventDayDate'],
        'eventDayId': !exists(json, 'eventDayId') ? undefined : json['eventDayId'],
        'foodCnt': !exists(json, 'foodCnt') ? undefined : json['foodCnt'],
        'foodType': !exists(json, 'foodType') ? undefined : json['foodType'],
        'freeTitle': !exists(json, 'freeTitle') ? undefined : json['freeTitle'],
        'groupId': !exists(json, 'groupId') ? undefined : json['groupId'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'partyId': !exists(json, 'partyId') ? undefined : json['partyId'],
        'placeId': !exists(json, 'placeId') ? undefined : json['placeId'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
    };
}

export function JsonEventCateringDayToJSON(value?: JsonEventCateringDay | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'changes': value.changes === undefined ? undefined : ((value.changes as Array<any>).map(JsonChangeOfobjectToJSON)),
        'companyId': value.companyId,
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'eventCateringDayId': value.eventCateringDayId,
        'eventDayDate': value.eventDayDate,
        'eventDayId': value.eventDayId,
        'foodCnt': value.foodCnt,
        'foodType': value.foodType,
        'freeTitle': value.freeTitle,
        'groupId': value.groupId,
        'note': value.note,
        'partyId': value.partyId,
        'placeId': value.placeId,
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
    };
}

