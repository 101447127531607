/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JsonWsMessage
 */
export interface JsonWsMessage {
    /**
     * 
     * @type {object}
     * @memberof JsonWsMessage
     */
    data?: object;
    /**
     * 
     * @type {string}
     * @memberof JsonWsMessage
     */
    type?: JsonWsMessageTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum JsonWsMessageTypeEnum {
    EventItem = 'EVENT_ITEM',
    Snapshot = 'SNAPSHOT',
    Error = 'ERROR',
    Bundle = 'BUNDLE'
}

export function JsonWsMessageFromJSON(json: any): JsonWsMessage {
    return JsonWsMessageFromJSONTyped(json, false);
}

export function JsonWsMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonWsMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : json['data'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function JsonWsMessageToJSON(value?: JsonWsMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data,
        'type': value.type,
    };
}

