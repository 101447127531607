import FormModal from "../../components/form/FormModal";
import {createOption, FAKE_VALUE_RESET, FormProps} from "../../model/form";
import {TAppFunction, useAppTranslation} from "../../services/i18n";
import {FormikErrors} from "formik";
import * as React from "react";
import {useCallback} from "react";
import FormContainer from "../form/FormContainer";
import {Grid} from "@mui/material";
import {SelectFormField} from "../form/SelectFormField";
import {CateringGroupType} from "./EventCateringMatrix";
import {useAppSelector} from "../../store";
import {selectCodebooks} from "../../store/selectors";

export interface CateringFormValueType {
    groupIds?: number[],
    partyIds?: number[],
    companyIds?: number[],
    freeTitles?: string[],
    placeId?: number,
    group?: CateringGroupType,
    note?: string
}


const validate = (values: CateringFormValueType, t: TAppFunction) => {
    let errors = {} as FormikErrors<CateringFormValueType>;
    if (!values.placeId) {
        errors.placeId = t('Vyberte prosím');
    } else if (!values.groupIds?.length && !values.partyIds?.length && !values.companyIds?.length && !values.freeTitles?.length) {
        errors.placeId = t('Není komu nastavit');
    }

    return errors;
}

interface Props extends FormProps<CateringFormValueType> {
    eventId: number,
    visibleGroupKeys: string[],
    availableGroups: CateringGroupType[]
}

const EventCateringForm = (props: Props) => {

    const t = useAppTranslation();
    const {placeFood} = useAppSelector(selectCodebooks);
    const {item} = props;

    const handleValidate = useCallback((v: CateringFormValueType) => {
        return validate(v, t);
    }, [t]);

    if (item?.placeId) {
        const options = placeFood
            .filter(o => o.value?.startsWith(props.eventId + ':'))
            .map(o => createOption(+o.value!.split(':')[1], o.label!!))
            .filter(o => o.value === item.placeId || !props.visibleGroupKeys?.find(rowKey => rowKey === item.group?.groupKey + ':' + o.value));
        options.unshift(createOption(FAKE_VALUE_RESET, t('(promazat řádek)')));

        return <FormContainer {...props} validate={handleValidate} children={() => <>
            <Grid item xs={12} sx={{marginTop: '12px'}}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <p>{t('Upravujete řádek')}: <strong>{props.item?.group?.title}</strong></p>
                    </Grid>
                    <Grid item xs={6}>
                        <SelectFormField name="placeId" label={'Místo'}
                            options={options}
                        />
                    </Grid>
                    {/*<Grid item xs={6}>*/}
                    {/*    <TextFormField name={'note'} label={'Poznámka'} />*/}
                    {/*</Grid>*/}
                    <br/>
                </Grid>
            </Grid>
        </>}/>;
    }

    return <FormContainer {...props} validate={handleValidate} children={(formikProps) => <>
        <Grid item xs={12}>
            <SelectFormField name="groupIds" placeholder={'Přidat skupinu'}
                options={props.availableGroups
                    .filter((g) => g.groupType === 'group')
                    .map(g => createOption(g.id!, g.title!))}
                isMulti={true}/>
        </Grid>
        <Grid item xs={12}>
            {t('a / nebo')}
            <SelectFormField name="partyIds" placeholder={'Přidat technickou osobu'}
                options={props.availableGroups
                    .filter((g) => g.groupType === 'party')
                    .map(g => createOption(g.id!, g.title!))}
                isMulti={true}/>
        </Grid>
        <Grid item xs={12}>
            {t('a / nebo')}
            <SelectFormField name="companyIds" placeholder={'Přidat společnost'}
                options={props.availableGroups
                    .filter((g) => g.groupType === 'company')
                    .map(g => createOption(g.id!, g.title!))}
                isMulti={true}/>
        </Grid>
        <Grid item xs={12}>
            {t('a / nebo')}
            <SelectFormField name="freeTitles" placeholder={'Přidat volný text'}
                options={props.availableGroups
                    .filter((g) => g.groupType === 'free')
                    .map(g => createOption(g.title!, g.title!))}
                isMulti={true}
                freeSolo={true}/>
        </Grid>
        {!!placeFood && <Grid item xs={4} sx={{marginTop: '12px'}}>
            {t('a všem nastavit stravu:')}
            <SelectFormField name="placeId" label={'Místo'}
                options={placeFood
                    .filter(o => o.value?.startsWith(props.eventId + ':'))
                    .map(o => createOption(+o.value!.split(':')[1], o.label!!))}
            />
        </Grid>}
    </>}/>;
}

const EventCateringModal = (props: Props) => {

    const t = useAppTranslation();

    return (
        <FormModal title={props.item?.placeId ? t('Úprava skupiny') : t('Přidat řádky')} {...props}>
            <EventCateringForm {...props}></EventCateringForm>
        </FormModal>
    );
}

export default EventCateringModal;
