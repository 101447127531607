/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonEventCateringDay,
    JsonEventCateringDayFromJSON,
    JsonEventCateringDayFromJSONTyped,
    JsonEventCateringDayToJSON,
} from './JsonEventCateringDay';

/**
 * 
 * @export
 * @interface JsonEventCateringData
 */
export interface JsonEventCateringData {
    /**
     * 
     * @type {Array<JsonEventCateringDay>}
     * @memberof JsonEventCateringData
     */
    items?: Array<JsonEventCateringDay>;
}

export function JsonEventCateringDataFromJSON(json: any): JsonEventCateringData {
    return JsonEventCateringDataFromJSONTyped(json, false);
}

export function JsonEventCateringDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonEventCateringData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': !exists(json, 'items') ? undefined : ((json['items'] as Array<any>).map(JsonEventCateringDayFromJSON)),
    };
}

export function JsonEventCateringDataToJSON(value?: JsonEventCateringData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : ((value.items as Array<any>).map(JsonEventCateringDayToJSON)),
    };
}

